import gql from "graphql-tag"
import moment from "moment"

import { games_insert_input, game_positions_insert_input } from "../../../types/globalTypes"
import { QueryName } from "../queryNames"

export const GAME_FIELDS = gql`
  fragment game_fields on games {
    id
    join_code
    minutes
    started_at
    status
    created_at
    root {
      id
      display_name
    }
    sequence {
      id
      display_name
    }
    positions(order_by: { score: desc }) {
      id
      score
      user {
        id
        display_name
      }
    }
  }
`

export const fetchGamesQuery = (teacher_id: string, name = QueryName.Games) => ({
  query: gql`
    ${GAME_FIELDS}
    query Games($teacher_id: String!) {
      games(where: { teacher_id: { _eq: $teacher_id } }) {
        ...game_fields
      }
    }
  `,
  variables: { teacher_id },
  name,
})

export const findGameQuery = (
  teacher_id: string,
  join_code: number,
  created_at = moment().subtract(12, "hours").utc().format(),
  name = QueryName.FindGame
) => ({
  query: gql`
    ${GAME_FIELDS}
    query FindGame($teacher_id: String!, $join_code: Int!, $created_at: timestamptz!) {
      games(
        where: { teacher_id: { _eq: $teacher_id }, join_code: { _eq: $join_code }, created_at: { _gt: $created_at } }
        order_by: { created_at: desc }
      ) {
        ...game_fields
      }
    }
  `,
  variables: { teacher_id, join_code, created_at },
  name,
})

export const insertGameQuery = (object: games_insert_input, name = QueryName.InsertGame) => ({
  query: gql`
    ${GAME_FIELDS}
    mutation InsertGame($object: games_insert_input!) {
      insert_games_one(object: $object) {
        ...game_fields
      }
    }
  `,
  variables: { object },
  name,
})

export const insertGamePositionQuery = (object: game_positions_insert_input, name = QueryName.InsertGamePosition) => ({
  query: gql`
    mutation InsertGamePosition($object: game_positions_insert_input!) {
      insert_game_positions_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
  name,
})

export const startGameQuery = (id: number, bufferSeconds: number) => ({
  query: gql`
    mutation StartGame($id: Int!, $started_at: timestamptz!) {
      update_games_by_pk(pk_columns: { id: $id }, _set: { status: "in progress", started_at: $started_at }) {
        id
      }
    }
  `,
  variables: { id, started_at: moment().add(bufferSeconds, "seconds").utc().format() },
})

export const completeGameQuery = (id: number) => ({
  query: gql`
    mutation CompleteGame($id: Int!) {
      update_games_by_pk(pk_columns: { id: $id }, _set: { status: "complete" }) {
        id
      }
    }
  `,
  variables: { id },
})

export const updateGamePositionQuery = (id: number) => ({
  query: gql`
    mutation UpdateGamePosition($id: Int!) {
      update_game_positions_by_pk(pk_columns: { id: $id }, _inc: { score: 1 }) {
        id
      }
    }
  `,
  variables: { id },
})
