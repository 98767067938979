import "isomorphic-fetch"
import { HttpLink, split, ApolloClient } from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import { WebSocketLink } from "@apollo/client/link/ws"
import { getMainDefinition } from "@apollo/client/utilities"

import CONFIG from "./config"
import { hasWindow } from "./lib/helpers"

const hasuraHostName = CONFIG.HASURA_API_URL.split("://")[1]

const wssUrl = `wss://${hasuraHostName}`
const httpUrl = `https://${hasuraHostName}`

const wsLink = hasWindow
  ? new WebSocketLink({
      uri: wssUrl,
      options: {
        reconnect: true,
        connectionParams: {
          headers: {
            "content-type": "application/json",
            "x-hasura-role": "admin",
            "x-hasura-admin-secret": CONFIG.HASURA_GRAPHQL_ADMIN_SECRET,
          },
        },
      },
    })
  : null

const httpLink = new HttpLink({ uri: httpUrl })

const link = hasWindow
  ? split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return kind === "OperationDefinition" && operation === "subscription"
      },
      wsLink,
      httpLink
    )
  : httpLink

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

export default client
