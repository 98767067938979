import { createSlice } from "@reduxjs/toolkit"
import last from "lodash/last"

import callHasura from "../callHasura"
import { Classroom, Classroom_classrooms_by_pk } from "../queries/types/Classroom"
import { ClassroomForJoinCode, ClassroomForJoinCode_classrooms } from "../queries/types/ClassroomForJoinCode"
import { Group, Group_groups_by_pk } from "../queries/types/Group"
import { InsertGroup_insert_groups_one } from "../queries/types/InsertGroup"
import { NotificationId, setNotificationAction } from "./notification"
import { PresignedUrlForReport } from "../queries/types/PresignedUrlForReport"
import { QueryName } from "../queryNames"
import { User_users_by_pk, User_users_by_pk_group } from "../queries/types/User"
import { captureSentryError, OLOG } from "../../lib/helpers"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import { fetchUserAction, updateUserGroupIdAction } from "./user"
import { updateUserClassroomQuery } from "../queries/user"

import {
  archiveClassroomQuery,
  fetchClassroomForJoinCodeQuery,
  fetchClassroomQuery,
  fetchGroupQuery,
  fetchPresignedUrlForReportQuery,
  insertClassroomQuery,
  insertGroupQuery,
  inviteTeachersQuery,
  joinCodeQuery,
  loginCardsQuery,
  loginsRawQuery,
  updateClassroomDisplayNameQuery,
} from "../queries/classroom"

export const validJoinGameCode = (code: string) => code.length === 4 && /^\d+$/.test(code)

export interface ClassroomState {
  isQuerying: any
  classroomForJoinCode?: ClassroomForJoinCode_classrooms
  loginsRaw: any[]
  classroom?: Classroom_classrooms_by_pk
  group?: Group_groups_by_pk
  presignedUrlForReport: string | null
}

const initialState: ClassroomState = {
  loginsRaw: [],
  isQuerying: {},
  presignedUrlForReport: null,
}

const classroomSlice = createSlice({
  name: "classroom",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchClassroomForJoinCodeSuccess: (state, { payload }: { payload: ClassroomForJoinCode_classrooms }) => {
      state.classroomForJoinCode = payload
    },

    fetchClassroomSuccess: (state, { payload }: { payload: Classroom_classrooms_by_pk }) => {
      state.classroom = payload
    },

    fetchGroupSuccess: (state, { payload }: { payload: Group_groups_by_pk }) => {
      state.group = payload
    },

    loginsRawSuccess: (state, { payload }: { payload: any[] }) => {
      state.loginsRaw = payload
    },

    unsetClassroomForJoinCode: (state) => {
      state.classroomForJoinCode = undefined
    },

    unsetLoginsRaw: (state) => {
      state.loginsRaw = []
    },

    fetchPresignedUrlForReportSuccess: (state, { payload }: { payload: string | null }) => {
      state.presignedUrlForReport = payload
    },
  },
})

export const {
  setLoading,
  networkingFailure,
  fetchPresignedUrlForReportSuccess,
  networkingSuccess,
  unsetLoginsRaw,
  loginsRawSuccess,
  fetchGroupSuccess,
  fetchClassroomForJoinCodeSuccess,
  fetchClassroomSuccess,
  unsetClassroomForJoinCode,
} = classroomSlice.actions

export const classroomSelector = (state: any) => state.classroom

export default classroomSlice.reducer

export function fetchClassroomForJoinCodeAction(code: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: ClassroomForJoinCode = await callHasura("", fetchClassroomForJoinCodeQuery(code))

      if (result.classrooms.length) {
        dispatch(fetchClassroomForJoinCodeSuccess(result.classrooms[0]))
      } else {
        captureSentryError("Join code not found code", { code })
        dispatch(setNotificationAction(NotificationId.JoinCodeNotFound))
      }
    } catch (error) {
      captureSentryError("Join code not found code", { code })
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function insertClassroomAction(accessToken: string, display_name: string, teacher_id: string) {
  return async (dispatch: any) => {
    const queryName = QueryName.InsertClassroom
    dispatch(setLoading(queryName))

    try {
      const { join_code }: { join_code: string } = await callHasura(accessToken, joinCodeQuery())

      if (join_code) {
        await callHasura(accessToken, insertClassroomQuery({ display_name, teacher_id, join_code }))
        await dispatch(fetchUserAction(accessToken, teacher_id))
        dispatch(setNotificationAction(NotificationId.ClassCreated))
        dispatch(networkingSuccess(queryName))
      } else {
        dispatch(networkingFailure([queryName, "No join code."]))
      }
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      if (String(error).includes("Uniqueness violation")) dispatch(setNotificationAction(NotificationId.ClassnameExists))
      dispatch(networkingFailure([queryName, error]))
    }
  }
}

export function updateClassroomDisplayNameAction(accessToken: string, id: number, display_name: string, teacherId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateClassroomDisplayNameQuery(id, display_name))
      dispatch(setNotificationAction(NotificationId.ClassUpdated))
      await dispatch(fetchUserAction(accessToken, teacherId))
      dispatch(networkingSuccess())
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      if (String(error).includes("Uniqueness violation")) dispatch(setNotificationAction(NotificationId.ClassnameExists))
      dispatch(networkingFailure())
    }
  }
}

export function unsetClassroomForJoinCodeAction() {
  return async (dispatch: any) => {
    dispatch(unsetClassroomForJoinCode())
  }
}

export function loginCardsAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    const query = loginCardsQuery(id)
    dispatch(setLoading(query.name))

    try {
      const result = await callHasura(accessToken, query)
      if (result.login_cards.length) window.open(result.login_cards)
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function loginsRawAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(unsetLoginsRaw())

    const query = loginsRawQuery(id)
    dispatch(setLoading(query.name))

    try {
      const result = await callHasura(accessToken, query)
      dispatch(loginsRawSuccess(result.logins_raw))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function joinClassAction(accessToken: string, code: string, userId: string) {
  return async (dispatch: any) => {
    const queryName = QueryName.JoinClass
    dispatch(setLoading(queryName))

    try {
      const result: ClassroomForJoinCode = await callHasura("", fetchClassroomForJoinCodeQuery(code))

      if (result.classrooms.length) {
        await callHasura("", updateUserClassroomQuery(userId, result.classrooms[0].id))
        await dispatch(fetchUserAction(accessToken, userId))
      } else {
        captureSentryError("Join code not found code", { code })

        if (validJoinGameCode(code)) {
          dispatch(setNotificationAction(NotificationId.EnteredGameCodeInsteadOfJoinCode))
        } else {
          dispatch(setNotificationAction(NotificationId.JoinCodeNotFound))
        }
      }

      dispatch(networkingSuccess(queryName))
    } catch (error: any) {
      captureSentryError("Join code not found code", { code })
      dispatch(networkingFailure([queryName, error]))
    }
  }
}

export function updateUserClassroomAction(accessToken: string, id: string, classroomId: number) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateUserClassroomQuery(id, classroomId))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function archiveClassroomAction(accessToken: string, id: number, userId: string, archived: boolean) {
  return async (dispatch: any) => {
    const query = archiveClassroomQuery(id, archived)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      await dispatch(fetchUserAction(accessToken!, userId))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function fetchClassroomAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    const query = fetchClassroomQuery(id)
    dispatch(setLoading())

    try {
      const result: Classroom = await callHasura(accessToken, query)
      if (result.classrooms_by_pk) dispatch(fetchClassroomSuccess(result.classrooms_by_pk))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchGroupAction(id: number) {
  return async (dispatch: any) => {
    const query = fetchGroupQuery(id)
    dispatch(setLoading())

    try {
      const result: Group = await callHasura("", query)
      if (result.groups_by_pk) dispatch(fetchGroupSuccess(result.groups_by_pk))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function removePresignedUrlAction() {
  return async (dispatch: any) => {
    dispatch(fetchPresignedUrlForReportSuccess(null))
  }
}

export function fetchPresignedUrlForReportAction(accessToken: string, aws_s3_url: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const bucket = aws_s3_url.split("//")[1].split(".s3.amazonaws")[0]
      const key = last(aws_s3_url.split("/"))
      if (key) {
        const result: PresignedUrlForReport = await callHasura(accessToken, fetchPresignedUrlForReportQuery(key, bucket))
        dispatch(fetchPresignedUrlForReportSuccess(result.presigned_url))
      }
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export const inviteTeachersAction = (accessToken: string, user: User_users_by_pk, emails: string[], message?: string) => {
  return async (dispatch: any) => {
    dispatch(setLoading())

    let group: User_users_by_pk_group | InsertGroup_insert_groups_one | null = user.group

    if (!group) {
      group = (await callHasura(accessToken, insertGroupQuery({ display_name: user.school }))).insert_groups_one
      if (!group) return

      await dispatch(updateUserGroupIdAction(accessToken, user.id, group.id))
      dispatch(fetchUserAction(accessToken, user.id))
    }

    const query = inviteTeachersQuery(group.id, user.display_name, group.display_name, emails, message)

    try {
      await callHasura(accessToken, query)
      dispatch(setNotificationAction(NotificationId.TeachersInvited))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}
