import { combineReducers } from "redux"

import assignmentReducer from "./assignment"
import bookReducer from "./book"
import choiceSetReducer from "./choiceSet"
import classroomReducer from "./classroom"
import conceptReducer from "./concept"
import curriculumReducer from "./curriculum"
import gameReducer from "./game"
import imageReducer from "./image"
import notificationReducer from "./notification"
import passageReducer from "./passage"
import sequenceReducer from "./sequence"
import userReducer from "./user"

const rootReducer = combineReducers({
  assignment: assignmentReducer,
  book: bookReducer,
  choiceSet: choiceSetReducer,
  classroom: classroomReducer,
  concept: conceptReducer,
  curriculum: curriculumReducer,
  game: gameReducer,
  image: imageReducer,
  notification: notificationReducer,
  passage: passageReducer,
  sequence: sequenceReducer,
  user: userReducer,
})

export default rootReducer
