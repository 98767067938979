import { OLOG } from "../../lib/helpers"

export type Errors = { [id: string]: string }

type ApiError = [string, string | undefined]
export type IsQuerying = { [id: string]: boolean }

export const defaultErrorMessage = "You may have lost internet connection. Please check and try again."
export const defaultStudentErrorMessage = "You may have lost internet connection. Please check and try again."

export const defaultSetLoading = (state: any, { payload }: { payload: string | undefined }) => {
  if (!payload) return

  state.isQuerying[payload] = true
  if (state.errors && state.errors[payload]) delete state.errors[payload]
}

export const defaultNetworkingSuccess = (state: any, { payload }: { payload: string | undefined }) => {
  if (!payload) return

  state.isQuerying[payload] = false
  if (state.errors && state.errors[payload]) delete state.errors[payload]
}

export const defaultNetworkingFailure = (state: any, { payload }: { payload: ApiError | undefined }) => {
  if (!payload) return

  state.isQuerying[payload[0]] = false
  if (!payload[1] || !state.errors) return

  state.errors[payload[0]] = payload[1]
  OLOG(`ERROR ${payload[1]}`, true)
}
