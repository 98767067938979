import flatten from "lodash/flatten"
import compact from "lodash/compact"
import uniq from "lodash/uniq"
import partition from "lodash/partition"
import extend from "lodash/extend"
import { createSlice } from "@reduxjs/toolkit"

import callFlask from "../../callFlask"
import callHasura, { HasuraError, hasuraErrorType } from "../callHasura"
import { Concept } from "./concept"
import { Concepts_concepts } from "../queries/types/Concepts"
import { Image, Image_images_by_pk } from "../queries/types/Image"
import { ImagesForConcepts, ImagesForConcepts_images } from "../queries/types/ImagesForConcepts"
import { InsertConcept } from "../queries/types/InsertConcept"
import { NotificationId, setNotificationAction } from "./notification"
import { QueryName } from "../queryNames"
import { UnenrichedPassage } from "./passage"
import { User_users_by_pk } from "../queries/types/User"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import { fetchUserAction, updateAdminConceptsQueueAction } from "./user"
import { imagesQueueFor } from "../../lib/helpers"
import { insertConceptImageAppearancesQuery, insertConceptQuery } from "../queries/concept"

import {
  deleteIllustratedPassageQuery,
  fetchImageQuery,
  fetchImagesForConceptQuery,
  fetchImagesForConceptsQuery,
  insertIllustratedPassagesQuery,
  updateAdminImagesQueueQuery,
  updateConceptImageAppearancePrimaryQuery,
  updateImageQuery,
} from "../queries/image"

export type GalleryImage = MinedImage | ImagesForConcepts_images

export const isMinedImage = (entity: MinedImage | ImagesForConcepts_images): entity is MinedImage => (entity as MinedImage).url !== undefined

type GalleryEntity = UnenrichedPassage | Concept

export interface MinedImage {
  thumbnail: string
  title: string
  url: string
  search_term: string
  source_url: string
  caption: string
}

export enum MinedImageSource {
  Flickr = "flickr",
  Wikipedia = "wikipedia",
}
export interface ImageState {
  isQuerying: any
  entity?: GalleryEntity
  image?: Image_images_by_pk
  minedImages?: MinedImage[]
  imagesForConcepts?: ImagesForConcepts_images[]
}

const initialState: ImageState = {
  isQuerying: {},
}

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    setGalleryEntity: (state, { payload }: { payload: GalleryEntity | undefined }) => {
      state.entity = payload
    },

    setMinedImages: (state, { payload }: { payload: MinedImage[] | undefined }) => {
      state.minedImages = payload
    },

    fetchImageSuccess: (state, { payload }: { payload: Image_images_by_pk | undefined }) => {
      state.image = payload
    },

    fetchImagesForConceptsSuccess: (state, { payload }: { payload: ImagesForConcepts_images[] }) => {
      state.imagesForConcepts = payload
    },
  },
})

export const {
  setLoading,
  networkingFailure,
  networkingSuccess,
  setGalleryEntity,
  setMinedImages,
  fetchImageSuccess,
  fetchImagesForConceptsSuccess,
} = imageSlice.actions

export const imageSelector = (state: any) => state.image

export default imageSlice.reducer

export function setGalleryEntityAction(entity?: GalleryEntity) {
  return async (dispatch: any) => {
    dispatch(setGalleryEntity(entity))
    if (!entity) dispatch(setMinedImages(undefined))
  }
}

export function updateConceptImageAppearancePrimaryAction(accessToken: string, id: number, primary: boolean) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateConceptImageAppearancePrimaryQuery(id, primary))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchImageAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    const query = fetchImageQuery(id)
    dispatch(setLoading(query.name))

    try {
      const result: Image = await callHasura(accessToken, query)
      if (result.images_by_pk) dispatch(fetchImageSuccess(result.images_by_pk))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error]))
    }
  }
}

export function fetchImagesForConceptsAction(accessToken: string, concepts: string[], hideNoResultsNotification = false) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: ImagesForConcepts =
        concepts.length === 10
          ? await callHasura(accessToken, fetchImagesForConceptQuery(concepts[0]))
          : await callHasura(accessToken, fetchImagesForConceptsQuery(concepts))
      if (!result.images.length && !hideNoResultsNotification) dispatch(setNotificationAction(NotificationId.NoResults))
      dispatch(fetchImagesForConceptsSuccess(result.images))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function mineImagesAction(search: string, fast: boolean) {
  return async (dispatch: any) => {
    const query = QueryName.MineImages
    dispatch(setLoading(query))
    dispatch(setMinedImages([]))

    try {
      const images = flatten(
        (
          await Promise.all([
            callFlask(`/images?source=${MinedImageSource.Wikipedia}&search=${search}${fast ? "&fast=true" : ""}`, "GET"),
            callFlask(`/images?source=${MinedImageSource.Flickr}&search=${search}`, "GET"),
          ])
        ).map((result) => result.images)
      )

      if (!images.length) dispatch(setNotificationAction(NotificationId.NoResults))
      dispatch(setMinedImages(images))
      dispatch(networkingSuccess(query))
    } catch (error: any) {
      dispatch(networkingFailure([query, error.message]))
    }
  }
}

interface PostConceptImagesResult {
  success: number
  concept_image_appearances: { id: number; image: { id: number } }[]
}

export function saveImagesForConceptAction(
  accessToken: string,
  user: User_users_by_pk,
  images: GalleryImage[],
  concept_id: number,
  search_term?: string
) {
  return async (dispatch: any) => {
    const query = QueryName.SaveImages
    dispatch(setLoading(QueryName.SaveImages))

    const [minedImages, imagesForConcept] = partition(images, isMinedImage)

    try {
      if (minedImages.length) {
        const result: PostConceptImagesResult = await callFlask("/images/concept", "POST", {
          images: images.map((m) => extend({}, m, { search_term })),
          concept_id,
        })
        const imageIds = result.concept_image_appearances.map((i) => i.image.id)
        const queue = imageIds.concat(...imagesQueueFor(user)).join()
        dispatch(updateAdminImagesQueueAction(accessToken, user.id, queue))
      }

      if (imagesForConcept.length) {
        await callHasura(accessToken, insertConceptImageAppearancesQuery(imagesForConcept.map((image) => ({ image_id: image.id, concept_id }))))
      }

      dispatch(networkingSuccess(query))
    } catch (error: any) {
      console.log(error)
      dispatch(networkingFailure([query, error.message]))
    }
  }
}

interface PostPassageImagesResult {
  success: number
  illustrated_passages: { id: number; image: { id: number } }[]
}

export function saveImagesAction(
  accessToken: string,
  curriculum_id: number,
  user: User_users_by_pk,
  images: GalleryImage[],
  passage_id: number,
  search_term?: string,
  concept?: Concepts_concepts
) {
  return async (dispatch: any) => {
    const query = QueryName.SaveImages
    dispatch(setLoading(QueryName.SaveImages))

    try {
      const [minedImages, imagesForConcept] = partition(images, isMinedImage)

      if (imagesForConcept.length) {
        await callHasura(accessToken, insertIllustratedPassagesQuery(imagesForConcept.map(({ id }) => ({ image_id: id, passage_id }))))
      }

      if (minedImages.length) {
        try {
          const display_name = minedImages[0].search_term
          let concept_id = concept?.id

          // create concept
          if (!concept_id) {
            const concept = { display_name, curriculum_id }
            const insertConceptResult: InsertConcept = await callHasura(accessToken, insertConceptQuery(concept))
            concept_id = insertConceptResult.insert_concepts_one?.id
          }

          // create images
          const result: PostPassageImagesResult = await callFlask("/images", "POST", {
            images: minedImages.map((m) => extend({}, m, { search_term, concept_id })),
            passage_id,
          })
          const imageIds = result.illustrated_passages.map((i) => i.image.id)

          // update queues
          const conceptQueue = uniq(compact([display_name].concat(...(user.concepts_queue_denormalized || "").split(",")))).join(",")
          dispatch(updateAdminConceptsQueueAction(accessToken, user.id, conceptQueue))
          const queue = imageIds.concat(...imagesQueueFor(user)).join()
          dispatch(updateAdminImagesQueueAction(accessToken, user.id, queue))
        } catch (error: any) {
          if (hasuraErrorType(error) === HasuraError.UniquenessViolation) {
            dispatch(setNotificationAction(NotificationId.UniquenessViolation))
          }
        }
      }

      dispatch(networkingSuccess(query))
    } catch (error: any) {
      dispatch(networkingFailure([query, error.message]))
    }
  }
}

export function deleteIllustratedPassageAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteIllustratedPassageQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateImageAction(accessToken: string, id: number, caption: string | null, quality: number | null) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateImageQuery(id, caption, quality))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateAdminImagesQueueAction(accessToken: string, id: string, images_queue_denormalized: string) {
  return async (dispatch: any) => {
    const query = updateAdminImagesQueueQuery(id, images_queue_denormalized)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      await dispatch(fetchUserAction(accessToken, id))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error]))
    }
  }
}
