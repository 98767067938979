import gql from "graphql-tag"

import { books_insert_input, experience_insert_input } from "../../../types/globalTypes"

export const fetchBooksQuery = (curriculum_id: number) => ({
  query: gql`
    query Books($curriculum_id: Int!) {
      books(where: { curriculum_id: { _eq: $curriculum_id } }, order_by: { title: asc }) {
        id
        additional_data
        created_at
        updated_at
        color
        image_aws_s3_url
        is_basic
        live
        seed
        title
        experiences {
          created_at
          additional_data
          correct
          seen
        }
      }
    }
  `,
  variables: { curriculum_id },
})

export const fetchQuestionsForBookQuery = (passageIds: number[], conceptIds: number[]) => ({
  query: gql`
    query QuestionsForBook($passageIds: [Int!]!, $conceptIds: [Int!]!) {
      passage_questions: questions(where: { read_mode: { _eq: false }, passage_id: { _in: $passageIds } }) {
        id
        passage_id
        read_mode
        type
        content
      }

      concept_questions: questions(where: { type: { _in: ["Concept To Definition"] }, read_mode: { _eq: false }, concept_id: { _in: $conceptIds } }) {
        id
        passage_id
        concept {
          display_name
        }
        read_mode
        type
        content
      }
    }
  `,
  variables: { passageIds, conceptIds },
})

export const insertBookExperienceQuery = (object: experience_insert_input) => ({
  query: gql`
    mutation InsertBookExperience($object: experience_insert_input!) {
      insert_experience_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const insertBookQuery = (object: books_insert_input) => ({
  query: gql`
    mutation InsertBook($object: books_insert_input!) {
      insert_books_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const updateBookExperienceQuery = (id: number, correct: number, seen: number, additional_data: any) => ({
  query: gql`
    mutation UpdateBookExperience($id: Int!, $correct: Int!, $seen: Int!, $additional_data: jsonb!) {
      update_experience_by_pk(pk_columns: { id: $id }, _set: { correct: $correct, seen: $seen, additional_data: $additional_data }) {
        id
      }
    }
  `,
  variables: { id, correct, seen, additional_data },
})
