import gql from "graphql-tag"

import { assignments_insert_input } from "../../../types/globalTypes"
import { QueryName } from "../queryNames"

export const insertAssignmentQuery = (object: assignments_insert_input, name = QueryName.InsertAssignment) => ({
  query: gql`
    mutation InsertAssignment($object: assignments_insert_input!) {
      insert_assignments_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
  name,
})

export const deleteAssignmentQuery = (id: number) => ({
  query: gql`
    mutation DeleteAssignment($id: Int!) {
      delete_assignments_by_pk(id: $id) {
        id
      }
    }
  `,
  variables: { id },
})

export const updateAssignmentQuery = (id: number, stars: number, due_date: string) => ({
  query: gql`
    mutation UpdateAssignment($id: Int!, $stars: Int!, $due_date: timestamptz!) {
      update_assignments_by_pk(pk_columns: { id: $id }, _set: { due_date: $due_date, stars: $stars }) {
        id
      }
    }
  `,
  variables: { id, stars, due_date },
})
