import { navigate } from "gatsby-link"
import { createSlice } from "@reduxjs/toolkit"

import callHasura from "../callHasura"
import { FindGame } from "../queries/types/FindGame"
import { Games, Games_games } from "../queries/types/Games"
import { InsertGame } from "../queries/types/InsertGame"
import { NotificationId, setNotificationAction } from "./notification"
import { User_users_by_pk } from "../queries/types/User"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess, defaultErrorMessage, Errors, IsQuerying } from "./common"
import { games_insert_input, game_positions_insert_input } from "../../../types/globalTypes"
import { captureSentryError } from "../../lib/helpers"

import {
  completeGameQuery,
  fetchGamesQuery,
  findGameQuery,
  insertGamePositionQuery,
  insertGameQuery,
  startGameQuery,
  updateGamePositionQuery,
} from "../queries/game"

export interface GameState {
  isQuerying: IsQuerying
  errors: Errors
  current?: number
  games: Games_games[]
  game?: Games_games
}

const initialState: GameState = { isQuerying: {}, errors: {}, games: [] }

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingSuccess: defaultNetworkingSuccess,
    networkingFailure: defaultNetworkingFailure,

    insertGameSuccess: (state, { payload }: { payload: number }) => {
      navigate(`/admin-game?id=${payload}`)
      state.current = payload
    },

    fetchGamesSuccess: (state, { payload }: { payload: Games_games[] }) => {
      state.games = payload
    },

    fetchGameSuccess: (state, { payload }: { payload: Games_games }) => {
      state.game = payload
    },
  },
})

export const { setLoading, networkingFailure, fetchGameSuccess, insertGameSuccess, networkingSuccess, fetchGamesSuccess } = gameSlice.actions

export const gameSelector = (state: any) => state.game

export default gameSlice.reducer

export function fetchGamesAction(teacherId: string) {
  return async (dispatch: any) => {
    const query = fetchGamesQuery(teacherId)
    dispatch(setLoading(query.name))

    try {
      const result: Games = await callHasura("", query)

      dispatch(fetchGamesSuccess(result.games))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function fetchGameAction(accessToken: string, teacherId: string, id: number) {
  return async (dispatch: any) => {
    const query = fetchGamesQuery(teacherId)
    dispatch(setLoading(query.name))

    try {
      const result: Games = await callHasura(accessToken, query)
      const game = result.games.find((g) => g.id === id)
      if (game) dispatch(fetchGameSuccess(game))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function findGameAction(accessToken: string, user: User_users_by_pk, code: number) {
  return async (dispatch: any) => {
    const query = findGameQuery(user.classroom?.teacher_id!, code)
    dispatch(setLoading(query.name))

    try {
      const result: FindGame = await callHasura("", query)
      const game = result.games[0]

      if (game) {
        const alreadyJoined = game?.positions.map((p) => p.user.id).includes(user!.id)

        if (!alreadyJoined) {
          const position: game_positions_insert_input = { game_id: game?.id, user_id: user?.id }
          dispatch(insertGamePositionAction(accessToken!, position))
        }

        navigate(`/waiting-game?id=${game?.id}`)
      } else {
        captureSentryError("Game code not found user", { code, user_id: user?.id })
        dispatch(setNotificationAction(NotificationId.GameCodeNotFound))
      }

      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function insertGameAction(accessToken: string, game: games_insert_input) {
  return async (dispatch: any) => {
    const query = insertGameQuery(game)
    dispatch(setLoading(query.name))

    try {
      const result: InsertGame = await callHasura(accessToken, query)
      const id = result.insert_games_one?.id

      if (id) {
        dispatch(insertGameSuccess(id))
        dispatch(networkingSuccess(query.name))
      } else {
        dispatch(networkingFailure([query.name, defaultErrorMessage]))
      }
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function insertGamePositionAction(accessToken: string, position: game_positions_insert_input) {
  return async (dispatch: any) => {
    const query = insertGamePositionQuery(position)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function updateGamePositionAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateGamePositionQuery(id))
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function startGameAction(accessToken: string, id: number, bufferSeconds: number) {
  return async (dispatch: any) => {
    const query = startGameQuery(id, bufferSeconds)
    dispatch(setLoading())

    try {
      await callHasura(accessToken, query)
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}

export function completeGameAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    const query = completeGameQuery(id)
    dispatch(setLoading())

    try {
      await callHasura(accessToken, query)
      dispatch(networkingSuccess())
    } catch (error: any) {
      dispatch(networkingFailure())
    }
  }
}
