import { configureStore } from "@reduxjs/toolkit"

import rootReducer from "./hasura/slices/index"

export default function createStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  })
}
