import capitalize from "lodash/capitalize"
import compact from "lodash/compact"
import confetti from "canvas-confetti"
import first from "lodash/first"
import isEqual from "lodash/isEqual"
import flatten from "lodash/flatten"
import get from "lodash/get"
import groupBy from "lodash/groupBy"
import intersection from "lodash/intersection"
import isNumber from "lodash/isNumber"
import jwt_decode from "jwt-decode"
import last from "lodash/last"
import moment, { Moment } from "moment"
import range from "lodash/range"
import sample from "lodash/sample"
import sortBy from "lodash/sortBy"
import toNumber from "lodash/toNumber"
import uniq from "lodash/uniq"
import { navigate } from "gatsby"

import CONFIG from "../config"
import { Role } from "../interfaces/role"
import { Query } from "../hasura/callHasura"
import { Game } from "../subscriptions/games"
import { Annotated, Passage, Token } from "../hasura/slices/passage"
import { User_users_by_pk, User_users_by_pk_experience, User_users_by_pk_experience_concept } from "../hasura/queries/types/User"
import { Prompt } from "../hasura/slices/sequence"
import { Concepts_concepts } from "../hasura/queries/types/Concepts"
import { Option } from "../interfaces/option"
import { Books_books, Books_books_experiences } from "../hasura/queries/types/Books"

export const OLOG = (data: any, isError?: boolean) => {
  if (CONFIG.VERBOSE || isError) console.log(data)
}

export const queryParams = () => {
  if (!hasWindow) return {}

  // @ts-ignore
  let obj = Object.fromEntries(new URLSearchParams(window.location.search))
  // @ts-ignore
  if (obj.id) obj.id = parseInt(obj.id, 10)
  return obj
}

export const orderElementsByArray = (elements: any[], arr: any[], key: string = "id"): any[] => {
  let result: any = []
  arr.forEach((id) => {
    const found = elements.find((element) => element[key] === id)
    if (found) result.push(found)
  })
  return result
}

export const imageResized = (key: string) => `https://s3.amazonaws.com/${key.replace(".jpg", "-resized.jpg")}`

export const imageFor = (key: string) => `https://s3.amazonaws.com/${key}`

export const sleep = (s: number) => new Promise((resolve) => setTimeout(resolve, s * 1000))

export const parseRole = (accessToken: string): Role => {
  const roles = get(jwt_decode(accessToken), ["https://hasura.io/jwt/claims", "x-hasura-allowed-roles"]) || []

  if (roles.includes(Role.Student)) {
    return Role.Student
  } else if (roles.includes(Role.Admin)) {
    return Role.Admin
  } else if (roles.includes(Role.Pedagogue)) {
    return Role.Pedagogue
  } else {
    return Role.Teacher
  }
}

export const hasWindow = typeof window !== `undefined`

export const getWindow = (paths: string[]) => (hasWindow ? get(window, paths) : undefined)

// @ts-ignore
function hex2(c) {
  c = Math.round(c)
  if (c < 0) c = 0
  if (c > 255) c = 255

  var s = c.toString(16)
  if (s.length < 2) s = "0" + s

  return s
}

// @ts-ignore
function color(r, g, b) {
  return "#" + hex2(r) + hex2(g) + hex2(b)
}

export const shade = (col: string, light: number): string => {
  var r = parseInt(col.substr(1, 2), 16)
  var g = parseInt(col.substr(3, 2), 16)
  var b = parseInt(col.substr(5, 2), 16)

  if (light < 0) {
    r = (1 + light) * r
    g = (1 + light) * g
    b = (1 + light) * b
  } else {
    r = (1 - light) * r + light * 255
    g = (1 - light) * g + light * 255
    b = (1 - light) * b + light * 255
  }

  return color(r, g, b)
}

export const pluralize = (text: string, n?: number) => `${withCommas(n)} ${text}${n == 1 ? "" : "s"}`

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text)

export const formatUsername = (email: string) => email.split("+dummy")[0].split("@")[0]

export const searchQueryParams = (attr: string): string | undefined => {
  const search = getWindow(["location", "search"])
  const queryParams = decodeURIComponent(search)
  const split = queryParams.split(`${attr}=`)
  if (split.length === 1) return

  return split[1].split("&")[0]
}

// @ts-ignore
export const queryName = (query: Query): string | undefined => first(query.query.definitions)?.name.value

export const alphabetize = (data: any[], attr: string, full?: boolean): any => {
  const none = "-"
  const sorted = sortBy(data, (d) => (get(d, attr) || none).toUpperCase())

  return groupBy(sorted, (x) => {
    const value = get(x, attr)
    if (value === none || value === null) return none
    if (parseInt(value[0], 10)) return "0-9"
    if (full) return value
    return value[0].match(/[a-z]/i) ? value[0].toUpperCase() : "Special (&, $, =, +, etc.)"
  })
}

export const withCommas = (n?: number) => (n || 0).toLocaleString("en", { useGrouping: true })

export const randomPassword = () => {
  const colors = ["gold", "orange", "blue", "silver", "brown", "purple", "green", "yellow", "red", "neon", "pink"]

  const animals = [
    "crab",
    "frog",
    "deer",
    "duck",
    "fly",
    "dragon",
    "panda",
    "spider",
    "turkey",
    "turtle",
    "walrus",
    "zebra",
    "rabbit",
    "lizard",
    "horse",
    "sheep",
    "hawk",
    "eagle",
    "cat",
    "dog",
    "fish",
    "bird",
    "snake",
    "lion",
    "tiger",
    "bear",
    "moose",
    "shark",
    "ant",
    "swan",
    "bee",
  ]

  return `${sample(colors)}${sample(animals)}`
}

export const getOrdinalPosition = (int: number | null) => {
  if (!isNumber(int)) return "N/A"
  const s = ["th", "st", "nd", "rd"]
  const v = int % 100
  return withCommas(int) + (s[(v - 20) % 10] || s[v] || s[0])
}

export const caseInsEq = (str1: string, str2: string): boolean => str1.toLowerCase() === str2.toLowerCase()

export const caseInsIncludes = (str1: string, strs: string[]): boolean => strs.some((str2) => caseInsEq(str1, str2))

export const caseInsStrIncludes = (str1: string, str2: string): boolean => str1.includes(str2)

export const adjustGameStartedAt = (game: Game, lateSeconds = 10) => {
  const diff = moment().diff(moment(game!.started_at), "seconds")
  const m = diff < lateSeconds ? moment() : moment(game!.started_at)
  return m.unix() * 1000 + (game!.minutes || 0) * 60 * 1000
}

export const shootConfetti = (particleCount = 7, colors?: string[]) => {
  const canvas = document.createElement("canvas")
  canvas.className = "fullscreen-confetti"
  document.body.appendChild(canvas)

  const myConfetti = confetti.create(canvas, { resize: true })
  const duration = 2 * 1000
  const end = Date.now() + duration

  ;(function frame() {
    myConfetti({ particleCount, angle: 60, spread: 55, origin: { x: 0 }, colors })
    myConfetti({ particleCount, angle: 120, spread: 55, origin: { x: 1 }, colors })
    if (Date.now() < end) requestAnimationFrame(frame)
  })()
}

export const navigateToLeaderboard = (game?: Game) => navigate(`/completed-game?id=${game?.id}`)

export const gameIsComplete = (game: Game) => {
  return moment(game!.started_at).add(game!.minutes, "minutes").isBefore()
}

export const trackHotjarEvent = (event: string, teacherId?: string) => {
  if (CONFIG.FILTER_HOTJAR_TEACHER_IDS.includes(teacherId || "")) return

  try {
    if (CONFIG.IS_DEVELOPMENT) {
      console.log("<HOTJAR_EVENT>", event)
    } else {
      // @ts-ignore
      window.hj("event", event)
    }
  } catch (error) {
    OLOG("ERROR running window.hj", true)
  }
}

export const getUserId = (accessToken: string) => get(jwt_decode(accessToken), ["https://hasura.io/jwt/claims", "x-hasura-user-id"])

export const preloadImage = (src: string) => {
  const preload = new Image()
  preload.src = imageFor(src)
}

export const preloadImages = (srcs: any[]) => srcs.forEach(preloadImage)

export const getMedal = (idx: number) =>
  ({
    0: `🥇 `,
    1: `🥈 `,
    2: `🥉 `,
  }[idx])

export const captureSentryError = (message: string, tags: { [key: string]: any } = {}) => {
  // @ts-ignore
  if (window.Sentry) window.Sentry.captureMessage(message, { tags })
}

export const lastNameComparator = (s: string) => first(last(s.split(" ")))

export const NO_PREPEND_SPACE_CHARS = [",", ".", "?", ")", ";", "-", ":", "%", "-"]
export const NO_PREPEND_SPACE_PREVIOUS_CHARS = ["(", "-"]

export const tokensJoined = (tokens: any[]) =>
  tokens.map((t: any, idx: number) => (includeRightMargin(tokens, t, idx, "originalText") ? `${t.originalText} ` : t.originalText)).join("")

export const includeRightMargin = (tokens: Token[], token: Token, idx: number, key = "value") => {
  const nextToken = tokens[idx + 1]
  const isLastToken = token.index === last(tokens)?.index
  // @ts-ignore
  const isNoPrependSpaceNextChar = !isLastToken && NO_PREPEND_SPACE_CHARS.includes(nextToken[key][0])
  // @ts-ignore
  const isNoAppendSpaceChar = NO_PREPEND_SPACE_PREVIOUS_CHARS.includes(tokens[idx][key])
  return !isLastToken && !isNoPrependSpaceNextChar && !isNoAppendSpaceChar
}

export const prependSpace = (prompt: Prompt[], idx: number) => {
  const result =
    !NO_PREPEND_SPACE_CHARS.includes(prompt[idx].value[0]) && (idx === 0 || !NO_PREPEND_SPACE_PREVIOUS_CHARS.includes(prompt[idx - 1].value))
  return result
}

export const numberWithCommas = (x: string | number) => (isNumber(x) ? x.toLocaleString() : x.replace(/\B(?=(\d{3})+(?!\d))/g, ","))

export const passagesQueueFor = (user: User_users_by_pk | undefined) =>
  user?.passages_queue_denormalized
    ?.split(",")
    .filter((s) => s)
    .map(toNumber) || []

export const imagesQueueFor = (user: User_users_by_pk | undefined) =>
  user?.images_queue_denormalized
    ?.split(",")
    .filter((s) => s)
    .map(toNumber) || []

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getImagesForUser = (user?: User_users_by_pk) => compact(user?.images_denormalized.split(",").map(toNumber))

export const instanceOfPassage = (object: any): object is Passage => "annotated" in object
export const instanceOfConcept = (object: any): object is Concepts_concepts => "grammar" in object
export const instanceOfConceptExperience = (object: any): object is User_users_by_pk_experience => "concept_id" in object

export const formatDate = (date?: Date) => {
  const MOMENT_TIME_FORMAT = "ddd, MMM Do h:mmA"
  return nearestMinutes(15, moment(date)).format(MOMENT_TIME_FORMAT)
}

export const downloadCsv = (filename: string, rows: string[]) => {
  const content = "data:text/csv;charset=utf-8," + rows.join("\n")
  const encodedUri = encodeURI(content)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()
}

export const unarchivedClassroomOptions = (user?: User_users_by_pk): Option[] =>
  (user?.classrooms || []).filter((c) => !c.archived).map((c) => ({ value: c.id, label: c.display_name }))

export const nearestMinutes = (interval: number, someMoment: Moment) => {
  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval
  return someMoment.clone().minute(roundedMinutes).second(0)
}

export const postSlackMessage = (text: string) => {
  if (CONFIG.IS_DEVELOPMENT) {
    console.log(`<SLACK> ${text}`)
    return
  }

  fetch(CONFIG.USERS_SLACK_CHANNEL_HOOK, { method: "POST", body: JSON.stringify({ text }) })
}

// export const shouldPlayDemo = (user: User_users_by_pk, isTeacher: boolean) => {
//   const hasPlayedDemo = localStorage.getItem("hasPlayedDemo") === "true"
//   const yes = !hasPlayedDemo && (isTeacher ? moment(user?.created_at).isAfter(moment().subtract(1, "day")) : user.level === 1)
//   if (yes) localStorage.setItem("hasPlayedDemo", "true")
//   return yes
// }

export const annotatedString = (annotated: Annotated) =>
  annotated.tokens.map((token, idx) => (includeRightMargin(annotated.tokens, token, idx) ? `${token.value} ` : token.value))

export const passageTextualRepresentation = (annotated: Annotated) =>
  compact([annotated.pretext, annotatedString(annotated).join(""), annotated.posttext]).join(" ")

export const conceptsForAnnotated = (annotated: Annotated, concepts?: Concepts_concepts[]) => {
  const lemmas = uniq(flatten(annotated.tokens.map((t) => t.lemma)))
  return (concepts || []).filter((c) => c.root_appearances.length && lemmas.includes(c.display_name))
}

export const getDefinitionHighlightIndexes = (concept: User_users_by_pk_experience_concept | Concepts_concepts) =>
  flatten(concept?.root_appearances.map((r) => range(r.definition_start_index, r.definition_end_index + 1)))

export const sharesRootWith = (concept: Concepts_concepts, otherConcept: Concepts_concepts) =>
  intersection(
    otherConcept.root_appearances.map((r) => r.root.display_name),
    concept.root_appearances.map((r) => r.root.display_name)
  ).length && otherConcept.display_name !== concept.display_name

export const sameRoots = (concept: Concepts_concepts, otherConcept: Concepts_concepts) =>
  isEqual(
    otherConcept.root_appearances.map((r) => r.root.display_name),
    concept.root_appearances.map((r) => r.root.display_name)
  )

export const bookIsUnlocked = (book: Books_books, user: User_users_by_pk, role: Role, hasPremium: boolean) => {
  if (role === Role.Admin) {
    return true
  } else if (role === Role.Teacher) {
    return book.is_basic || hasPremium
  } else {
    return (
      book.experiences.length ||
      book.additional_data["default_unlocked"] ||
      (user?.level || 0) >= book.additional_data["unlock_level"] ||
      user?.classroom?.assignments.some((a) => a.book?.id === book.id)
    )
  }
}

export const getTeacherId = (user?: User_users_by_pk) => user?.classroom?.teacher_id || user?.id

export const formatLeaderboardName = (name?: string) => `${capitalize(last(name?.split("-"))).replace("Eternity", "All Time")} Leaderboard`

export const getInitials = (string: string) => {
  const names = string.replace(/[^a-z]/gi, "").split(" ")

  if (names.length === 1) {
    return names[0].slice(0, 2).toUpperCase()
  }

  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }

  return initials
}

export const parseIdFrom = (accessToken: string): string | undefined => get(jwt_decode(accessToken), "sub")

export const commaSeparatedString = (arr: string[]): string | undefined => {
  if (!arr.length) return

  if (arr.length === 1) return arr[0]

  const firsts = arr.slice(0, arr.length - 1)
  const last = arr[arr.length - 1]
  return firsts.join(", ") + " and " + last
}

export const maxClassroomsReached = (hasPremium: boolean, user?: User_users_by_pk) =>
  !hasPremium && (user?.classrooms.filter((c) => !c.archived).length || 0) >= CONFIG.MAX_CLASSES_BASE

export const getMasteredCount = (experience?: Books_books_experiences) =>
  (experience?.additional_data["runs"] || []).filter((run: any) => run.seen === run.correct && !run.hintsUsed).length

export const soundEffectsEnabled = (user?: User_users_by_pk) =>
  user?.classroom ? user.classroom.teacher.sound_effects_enabled : user?.sound_effects_enabled
