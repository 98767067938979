import { createSlice } from "@reduxjs/toolkit"
import { books_insert_input, experience_insert_input } from "../../../types/globalTypes"

import callHasura from "../callHasura"
import { fetchBooksQuery, fetchQuestionsForBookQuery, insertBookExperienceQuery, insertBookQuery, updateBookExperienceQuery } from "../queries/book"
import { Books, Books_books } from "../queries/types/Books"
import { QuestionsForBook, QuestionsForBook_concept_questions, QuestionsForBook_passage_questions } from "../queries/types/QuestionsForBook"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import CONFIG from "../../config"

export type BookQuestion = QuestionsForBook_concept_questions | QuestionsForBook_passage_questions
export interface BookState {
  isQuerying: any
  books: Books_books[]
  questionsForBook?: QuestionsForBook
  questionsForBookFiltered?: BookQuestion[]
}

const initialState: BookState = {
  books: [],
  isQuerying: {},
}

const bookSlice = createSlice({
  name: "book",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchBooksSuccess: (state, { payload }: { payload: Books_books[] }) => {
      state.books = payload
    },

    fetchQuestionsForBookSuccess: (state, { payload }: { payload: QuestionsForBook }) => {
      state.questionsForBook = payload
    },

    setQuestionsForBookFiltered: (state, { payload }: { payload: BookQuestion[] }) => {
      state.questionsForBookFiltered = payload
    },
  },
})

export const { setLoading, networkingFailure, networkingSuccess, fetchBooksSuccess, setQuestionsForBookFiltered, fetchQuestionsForBookSuccess } =
  bookSlice.actions

export const bookSelector = (state: any) => state.book

export default bookSlice.reducer

export function fetchBooksAction(accessToken: string, curriculum_id: number = CONFIG.CORE_CURRICULUM_ID) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: Books = await callHasura(accessToken, fetchBooksQuery(curriculum_id))
      dispatch(fetchBooksSuccess(result.books))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchQuestionsForBookAction(accessToken: string, passageIds: number[], conceptIds: number[]) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const questions: QuestionsForBook = await callHasura(accessToken, fetchQuestionsForBookQuery(passageIds, conceptIds))
      dispatch(fetchQuestionsForBookSuccess(questions))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertBookExperienceAction(accessToken: string, object: experience_insert_input) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertBookExperienceQuery(object))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertBookAction(accessToken: string, object: books_insert_input) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertBookQuery(object))
      await dispatch(fetchBooksAction(accessToken))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateBookExperienceAction(accessToken: string, id: number, correct: number, seen: number, additional_data: any) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateBookExperienceQuery(id, correct, seen, additional_data))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function setQuestionsForBookFilteredAction(questionsForBookFiltered: BookQuestion[]) {
  return async (dispatch: any) => {
    dispatch(setQuestionsForBookFiltered(questionsForBookFiltered))
  }
}
