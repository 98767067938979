import gql from "graphql-tag"

import { QueryName } from "../queryNames"
import { Annotated } from "../slices/passage"
import { passages_insert_input } from "../../../types/globalTypes"

const PASSAGE_FRAGMENT = gql`
  fragment passage_fields on passages {
    id
    annotated
    concepts_denormalized
    created_at
    custom_questions
    difficulty
    is_complete
    original_text_sentence_tokenized
    status
    text
    updated_at

    illustrated_passages(order_by: { image: { quality: desc_nulls_last } }) {
      id
      image {
        id
        caption
        quality
        s3_path
        status
        source
      }
    }

    questions {
      id
    }
  }
`

const UNENRICHED_PASSAGE_FRAGMENT = gql`
  fragment unenriched_passage_fields on passages {
    id
    annotated
    concepts_denormalized
    created_at
    difficulty
    original_text_sentence_tokenized
    original_text_target_sentence_index
    quality
    score
    source
    status
    text
    custom_questions
    illustrated_passages {
      id
      image {
        s3_path
      }
    }
  }
`

export const fetchPassagesForUserQuery = (user_id: string) => ({
  query: gql`
    ${PASSAGE_FRAGMENT}
    query PassagesForUser($user_id: String!) {
      passages(where: { annotated: { _is_null: false }, experiences: { user_id: { _eq: $user_id } } }) {
        ...passage_fields
      }
    }
  `,
  variables: { user_id },
})

export const fetchUnenrichedPassageConceptsQuery = () => ({
  query: gql`
    query UnenrichedPassageConcepts {
      passages(where: { status: { _is_null: true } }) {
        id
        concepts_denormalized
      }
    }
  `,
})

export const fetchUnenrichedPassagesForAdminQuery = (concept?: string, name = QueryName.FetchPassages) => ({
  query: concept
    ? gql`
        ${UNENRICHED_PASSAGE_FRAGMENT}
        query UnenrichedPassagesForConcept($concept: String!) {
          passages(where: { status: { _is_null: true }, concepts_denormalized: { _ilike: $concept } }, order_by: { score: desc }, limit: 100) {
            ...unenriched_passage_fields
          }
        }
      `
    : gql`
        ${UNENRICHED_PASSAGE_FRAGMENT}
        query UnenrichedPassagesForAdmin {
          passages(where: { status: { _is_null: true } }, order_by: { updated_at: desc }, limit: 100) {
            ...unenriched_passage_fields
          }
        }
      `,
  variables: { concept: concept ? `%${concept}%` : undefined },
  name,
})

export const fetchPassagesForLibraryQuery = (curriculum_id: number, name = QueryName.FetchPassages) => ({
  query: gql`
    ${PASSAGE_FRAGMENT}
    query PassagesForLibrary($curriculum_id: Int!) {
      passages(where: { curriculum_id: { _eq: $curriculum_id }, status: { _in: ["done", "review"] } }, order_by: { id: desc }) {
        ...passage_fields
      }
    }
  `,
  variables: { curriculum_id },
  name,
})

export const fetchPassagesForConceptQuery = (concept?: string) => ({
  query: gql`
    ${PASSAGE_FRAGMENT}
    query PassagesForConcept($concept: String!) {
      passages(
        where: { concepts_denormalized: { _ilike: $concept }, annotated: { _is_null: false }, status: { _eq: "done" } }
        order_by: { updated_at: desc }
      ) {
        ...passage_fields
      }
    }
  `,
  variables: { concept: `%${concept}%` },
})

export const fetchPassagesForIdsQuery = (ids: number[]) => ({
  query: gql`
    ${PASSAGE_FRAGMENT}
    query PassagesForIds($ids: [Int!]!) {
      passages(where: { id: { _in: $ids } }) {
        ...passage_fields
      }
    }
  `,
  variables: { ids },
})

export const fetchUnenrichedPassagesForIdsQuery = (ids: number[], name = QueryName.FetchPassages) => ({
  query: gql`
    ${UNENRICHED_PASSAGE_FRAGMENT}
    query UnenrichedPassagesForIds($ids: [Int!]!) {
      passages(where: { id: { _in: $ids } }) {
        ...unenriched_passage_fields
      }
    }
  `,
  variables: { ids },
  name,
})

export const updatePassageQuery = (annotated: Annotated, userId: string, id: number) => ({
  query: gql`
    mutation UpdatePassage($id: Int!, $userId: String!, $annotated: String!) {
      update_passage(id: $id, userId: $userId, annotated: $annotated)
    }
  `,
  variables: { id, userId, annotated: encodeURIComponent(JSON.stringify(annotated)) },
})

export const updatePassageStatusQuery = (id: number, status: string) => ({
  query: gql`
    mutation UpdatePassageStatus($id: Int!, $status: String!) {
      update_passages_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
        id
      }
    }
  `,
  variables: { id, status },
})

export const updatePassageCustomQuestionsQuery = (id: number, custom_questions: any) => ({
  query: gql`
    mutation UpdatePassageCustomQuestions($id: Int!, $custom_questions: jsonb!) {
      update_passages_by_pk(pk_columns: { id: $id }, _set: { custom_questions: $custom_questions }) {
        id
      }
    }
  `,
  variables: { id, custom_questions },
})

export const updatePassageMetadataQuery = (
  id: number,
  quality: number | null,
  difficulty: number | null,
  name = QueryName.UpdatePassageMetadata
) => ({
  query: gql`
    mutation UpdatePassageMetadata($id: Int!, $quality: Int, $difficulty: Int) {
      update_passages_by_pk(pk_columns: { id: $id }, _set: { quality: $quality, difficulty: $difficulty }) {
        id
      }
    }
  `,
  variables: { id, quality, difficulty },
  name,
})

export const updatePassageConceptsQuery = (id: number, concepts_denormalized: string) => ({
  query: gql`
    mutation UpdatePassageConcepts($id: Int!, $concepts_denormalized: String!) {
      update_passages_by_pk(pk_columns: { id: $id }, _set: { concepts_denormalized: $concepts_denormalized }) {
        id
      }
    }
  `,
  variables: { concepts_denormalized, id },
})

export const updatePassageIsCompleteQuery = (id: number, is_complete: boolean) => ({
  query: gql`
    mutation UpdatePassageIsComplete($id: Int!, $is_complete: Boolean!) {
      update_passages_by_pk(pk_columns: { id: $id }, _set: { is_complete: $is_complete }) {
        id
      }
    }
  `,
  variables: { is_complete, id },
})

export const insertPassagesQuery = (objects: passages_insert_input[], name = QueryName.InsertPassages) => ({
  query: gql`
    mutation InsertPassages($objects: [passages_insert_input!]!) {
      insert_passages(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  variables: { objects },
  name,
})

export const deletePassageQuery = (id: number) => ({
  query: gql`
    mutation DeletePassage($id: Int!) {
      delete_passages_by_pk(id: $id) {
        id
      }
    }
  `,
  variables: { id },
})
