import flatten from "lodash/flatten"
import sumBy from "lodash/sumBy"
import shuffle from "lodash/shuffle"
import range from "lodash/range"
import sampleSize from "lodash/sampleSize"
import toNumber from "lodash/toNumber"
import uniqBy from "lodash/uniqBy"
import { createSlice } from "@reduxjs/toolkit"

import callFlask from "../../callFlask"
import callHasura from "../callHasura"
import { ClassroomExperience } from "../queries/types/ClassroomExperience"
import { Curriculums, Curriculums_curriculums } from "../queries/types/Curriculums"
import { EntityCounts } from "../queries/types/EntityCounts"
import { IntermissionData } from "../../services/intermission"
import { OLOG } from "../../lib/helpers"
import { Pedagogues } from "../queries/types/Pedagogues"
import { ReportData, ReportData_classrooms_by_pk_students_experience } from "../queries/types/ReportData"
import { Sequences_sequences } from "../queries/types/Sequences"
import { defaultSetLoading, defaultNetworkingFailure } from "./common"
import {
  fetchClassroomExperienceQuery,
  fetchCurriculumsQuery,
  fetchEntityCountsQuery,
  fetchPedagoguesQuery,
  fetchRandomSequenceQuery,
} from "../queries/curriculum"
import { networkingSuccess } from "./user"
import CONFIG from "../../config"
import { RandomSequence } from "../queries/types/RandomSequence"

export interface CurriculumState {
  curriculums: Curriculums_curriculums[]
  curriculum?: Curriculums_curriculums
  entityCounts?: EntityCounts
  classroomExperience?: ClassroomExperience
  crossword?: Crossword
  queuedPassageIds: number[]
  intermissionData?: IntermissionData
  isCore: boolean
}

const initialState: CurriculumState = {
  curriculums: [],
  queuedPassageIds: [],
  isCore: true,
}

const curriculumSlice = createSlice({
  name: "curriculum",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,

    setCurriculum: (state, { payload }) => {
      state.curriculum = payload
      state.isCore = payload.id === CONFIG.CORE_CURRICULUM_ID
    },

    fetchCurriculumsSuccess: (state, { payload }: { payload: { curriculums: Curriculums_curriculums[]; curriculumId: number } }) => {
      state.curriculums = payload.curriculums
      const curriculum = payload.curriculums.find((p: any) => p.id === payload.curriculumId)
      state.curriculum = curriculum
      state.isCore = payload.curriculumId === CONFIG.CORE_CURRICULUM_ID
    },

    fetchEntityCountsSuccess: (state, { payload }: { payload: EntityCounts }) => {
      state.entityCounts = payload
    },

    fetchClassroomExperienceSuccess: (state, { payload }: { payload: ClassroomExperience }) => {
      state.classroomExperience = payload
    },

    createCrosswordSuccess: (state, { payload }: { payload: Crossword }) => {
      state.crossword = payload
    },

    unsetCrossword: (state) => {
      state.crossword = undefined
    },

    setIntermissionData: (state, { payload }: { payload: IntermissionData | undefined }) => {
      state.intermissionData = payload
    },

    setQueued: (state, { payload }: { payload: number[] }) => {
      state.queuedPassageIds = payload
    },
  },
})

export const {
  setLoading,
  networkingFailure,
  setCurriculum,
  fetchClassroomExperienceSuccess,
  fetchCurriculumsSuccess,
  createCrosswordSuccess,
  unsetCrossword,
  fetchEntityCountsSuccess,
  setQueued,
  setIntermissionData,
} = curriculumSlice.actions

export const curriculumSelector = (state: any) => state.curriculum

export default curriculumSlice.reducer

export function fetchPedagoguesAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: Pedagogues = await callHasura(accessToken, fetchPedagoguesQuery())
      const passageIds = flatten(result.users.map((r) => (r.passages_queue_denormalized || "").split(","))).map(toNumber)
      dispatch(setQueued(passageIds))
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export interface Node {
  id: string
  isRoot: boolean
  stats?: {
    correct: number
    seen: number
    accuracy: number
  }
}

export interface ForceNetworkData {
  nodes: Node[]
  links: { source: string; target: string }[]
}

export const forceNetworkForConcepts = (data: ClassroomExperience, concepts: string[]): ForceNetworkData => {
  console.log(`creating force network for ${concepts.join(", ")}...`)
  const links = flatten(
    data.concepts.filter((c) => concepts.includes(c.display_name)).map((c) => c.root_appearances.map((r) => [r.root.display_name, c.display_name]))
  )
  const nodes = uniqBy(links.map((l) => ({ id: l[0], isRoot: true })).concat(...flatten(links.map((l) => ({ id: l[1], isRoot: false })))), "id")
  console.log(`${links.length} links; ${nodes.length} nodes `)
  return { nodes, links: links.map((l) => ({ source: l[0], target: l[1] })) }
}

export const forceNetworkForExperience = (data: ReportData, experience: ReportData_classrooms_by_pk_students_experience[]): ForceNetworkData => {
  console.log(`creating force network for ${experience.length} experience...`)

  const links = flatten(
    data.concepts
      .filter((c) => experience.some((e) => e.concept?.id === c.id))
      .map((c) => c.root_appearances.map((r) => [r.root.display_name, c.display_name]))
  )

  const nodes = uniqBy(links.map((l) => ({ id: l[0], isRoot: true })).concat(...flatten(links.map((l) => ({ id: l[1], isRoot: false })))), "id").map(
    (n: Node) => {
      if (n.isRoot) {
        const experienceForRoot = experience.filter((e) => e.concept?.root_appearances.some((a) => a.root.display_name === n.id))
        const correct = sumBy(experienceForRoot, "correct")
        const seen = sumBy(experienceForRoot, "seen")
        n.stats = { correct, seen, accuracy: Math.round((correct * 100) / seen) }
      }

      return n
    }
  )

  console.log(`${links.length} links; ${nodes.length} nodes `)
  return { nodes, links: links.map((l) => ({ source: l[0], target: l[1] })) }
}

export function fetchClassroomExperienceAction(accessToken: string, classroomId: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: ClassroomExperience = await callHasura(accessToken, fetchClassroomExperienceQuery(classroomId))
      dispatch(fetchClassroomExperienceSuccess(result))
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function fetchEntityCountsAction() {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: EntityCounts = await callHasura("", fetchEntityCountsQuery())
      dispatch(fetchEntityCountsSuccess(result))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function fetchRandomSequenceAction(curriculum_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const randomSequence: RandomSequence = await callHasura("", fetchRandomSequenceQuery(curriculum_id))
      window.open(
        `http://app.playwordcraft.com/play?type=test&ids=${sampleSize(
          randomSequence.questions.map((q) => q.id),
          10
        ).join()}`,
        "_blank"
      )
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function fetchCurriculumsAction(accessToken: string, curriculumId: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: Curriculums = await callHasura(accessToken, fetchCurriculumsQuery())
      dispatch(fetchCurriculumsSuccess({ curriculums: result.curriculums, curriculumId }))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function setCurriculumAction(curriculum: Curriculums_curriculums) {
  return (dispatch: any) => dispatch(setCurriculum(curriculum))
}

export function setIntermissionDataAction(data?: IntermissionData) {
  return (dispatch: any) => dispatch(setIntermissionData(data))
}

type Clue = (number | string)[]

interface CrosswordLegend {
  down: Clue[]
  across: Clue[]
}
interface Crossword {
  grid: string[][]
  display: (string | number)[][]
  legend: CrosswordLegend
}

export function createCrosswordAction(sequence: Sequences_sequences) {
  const items = sequence.sequence_items
    .filter((i) => i.concept?.definition)
    .map((i) => {
      let { display_name, definition, root_appearances } = i.concept!
      const capitalize = root_appearances.map((r) => range(r.definition_start_index, r.definition_end_index + 1))
      const rootsCapitalizedDefinition = definition!
        .split("")
        .map((char, idx) => (capitalize.some((range) => range.includes(idx)) ? char.toUpperCase() : char))
        .join("")
      const capitalizeRoot = root_appearances.map((r) => range(r.start_index, r.start_index + r.value.length))
      const conceptRootConnectorsCapitalized = display_name
        .split("")
        .map((char, idx) => (!capitalizeRoot.some((range) => range.includes(idx)) ? char.toUpperCase() : char))
        .join("")
      return [conceptRootConnectorsCapitalized, rootsCapitalizedDefinition]
    })

  return async (dispatch: any) => {
    dispatch(setLoading())
    dispatch(unsetCrossword())

    try {
      const result: Crossword = await callFlask("/crossword", "POST", shuffle(items))
      dispatch(createCrosswordSuccess(result))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}
