import gql from "graphql-tag"

import { choice_sets_insert_input } from "../../../types/globalTypes"

export const fetchChoiceSetsQuery = (curriculum_id: number) => ({
  query: gql`
    query ChoiceSets($curriculum_id: Int!) {
      choice_sets(where: { curriculum_id: { _eq: $curriculum_id } }) {
        id
        display_name
        concepts_denormalized
        logical_quantifier
      }
    }
  `,
  variables: { curriculum_id },
})

export const insertChoiceSetQuery = (object: choice_sets_insert_input) => ({
  query: gql`
    mutation InsertChoiceSet($object: choice_sets_insert_input!) {
      insert_choice_sets_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const updateChoiceSetQuery = (id: number, concepts: string[]) => ({
  query: gql`
    mutation UpdateChoiceSet($id: Int!, $concepts_denormalized: String!) {
      update_choice_sets_by_pk(_set: { concepts_denormalized: $concepts_denormalized }, pk_columns: { id: $id }) {
        id
      }
    }
  `,
  variables: { id, concepts_denormalized: concepts.join() },
})
