import gql from "graphql-tag"

import { illustrated_passages_insert_input } from "../../../types/globalTypes"
import { QueryName } from "../queryNames"

export const deleteIllustratedPassageQuery = (id: number) => ({
  query: gql`
    mutation DeleteIllustratedPassage($id: Int!) {
      delete_illustrated_passages_by_pk(id: $id) {
        id
      }
    }
  `,
  variables: { id },
})

export const insertIllustratedPassagesQuery = (objects: illustrated_passages_insert_input[]) => ({
  query: gql`
    mutation InsertIllustratedPassages($objects: [illustrated_passages_insert_input!]!) {
      insert_illustrated_passages(objects: $objects) {
        affected_rows
      }
    }
  `,
  variables: { objects },
})

export const fetchImageQuery = (id: number, name = QueryName.FetchImage) => ({
  query: gql`
    query Image($id: Int!) {
      images_by_pk(id: $id) {
        id
        caption
        created_at
        difficulty
        permission
        quality
        search_term
        s3_path
        source
        updated_at
        illustrated_passages {
          passage {
            annotated
          }
        }
        concept_image_appearances {
          id
          primary
          concept {
            display_name
          }
        }
      }
    }
  `,
  variables: { id },
  name,
})

export const updateImageQuery = (id: number, caption: string | null, quality: number | null) => ({
  query: gql`
    mutation UpdateImage($id: Int!, $caption: String, $quality: Int) {
      update_images_by_pk(pk_columns: { id: $id }, _set: { caption: $caption, quality: $quality, status: "done" }) {
        id
      }
    }
  `,
  variables: { id, caption, quality },
})

export const updateConceptImageAppearancePrimaryQuery = (id: number, primary: boolean) => ({
  query: gql`
    mutation UpdateConceptImageAppearancePrimary($id: Int!, $primary: Boolean!) {
      update_concept_image_appearances_by_pk(pk_columns: { id: $id }, _set: { primary: $primary }) {
        id
      }
    }
  `,
  variables: { id, primary },
})

export const updateAdminImagesQueueQuery = (id: string, images_queue_denormalized: string, name = QueryName.UpdateAdminImagesQueue) => ({
  query: gql`
    mutation UpdateAdminImagesQueue($id: String!, $images_queue_denormalized: String!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { images_queue_denormalized: $images_queue_denormalized }) {
        id
      }
    }
  `,
  variables: { id, images_queue_denormalized },
  name,
})

export const fetchImagesForConceptsQuery = (concepts: string[]) => ({
  query: gql`
    query ImagesForConcepts($concepts: [String!]!) {
      images(
        where: { _or: [{ concept_image_appearances: { concept: { display_name: { _in: $concepts } } } }, { search_term: { _in: $concepts } }] }
      ) {
        id
        s3_path
        concept_image_appearances {
          concept {
            display_name
          }
        }
      }
    }
  `,
  variables: { concepts },
})

export const fetchImagesForConceptQuery = (concept: string) => ({
  query: gql`
    query ImagesForConcepts2($concept: String!) {
      images(
        where: { _or: [{ concept_image_appearances: { concept: { display_name: { _ilike: $concept } } } }, { search_term: { _ilike: $concept } }] }
      ) {
        id
        s3_path
        concept_image_appearances {
          concept {
            display_name
          }
        }
      }
    }
  `,
  variables: { concept: `%${concept}%` },
})
