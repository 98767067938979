import gql from "graphql-tag"
import moment from "moment"

import CONFIG from "../../config"
import { QueryName } from "../queryNames"
import { pedagogue_events_insert_input, user_events_insert_input, user_sequences_insert_input } from "../../../types/globalTypes"

export enum LeaderboardGroup {
  Global = "global",
  Class = "class",
}

export enum LeaderboardType {
  Stars = "stars",
  Accuracy = "accuracy",
}

export enum LeaderboardTime {
  Weekly = "weekly",
  Eternity = "eternity",
}

export const USER_FIELDS = gql`
  fragment user_fields on users {
    id
    accuracy
    ai_report_section_enabled
    concepts_discovered
    concepts_mastered
    concepts_queue_denormalized
    created_at
    curriculum_id
    display_name
    email
    enable_billing_flow
    grades
    has_premium
    images_denormalized
    images_queue_denormalized
    is_individual
    leaderboards_disabled
    level
    other_queues
    passages_discovered
    passages_queue_denormalized
    pause_subscription_in_summer
    school
    settings
    sound_effects_enabled
    stars
    stripe_id
    subrole
    unlocked_denormalized
    related_accounts {
      stripe_profile {
        id
        subscriptions {
          total_count
          data {
            cancel_at
            current_period_end
            current_period_start
            plan {
              product
            }
          }
        }
      }
    }
    classroom {
      id
      display_name
      teacher_id
      teacher {
        created_at
        leaderboards_disabled
        sound_effects_enabled
        settings
      }
      assignments(where: { is_complete: { _eq: false } }, order_by: { id: desc }) {
        id
        classroom_id
        created_at
        data
        due_date
        duration
        is_complete
        stars
        updated_at
        book {
          id
          seed
          title
        }
      }
    }
    classrooms(order_by: { archived: asc, id: asc }) {
      id
      created_at
      updated_at
      display_name
      join_code
      archived
      assignments(order_by: { id: desc }) {
        id
        classroom_id
        created_at
        data
        due_date
        duration
        is_complete
        stars
        updated_at
        book {
          id
          seed
          title
        }
      }
      students(where: { role: { _neq: "teacher" } }, order_by: { display_name: asc }) {
        id
        accuracy
        concepts_discovered
        concepts_mastered
        display_name
        email
        historical_stats
        level
        stars
        total_correct
        total_seen
        experience(where: { book_id: { _is_null: false } }) {
          book_id
          additional_data
        }
        reports(order_by: { id: desc }, limit: 3) {
          aws_s3_url
          created_at
        }
        user_sequences {
          started_at
          seconds
        }
      }
    }
    experience {
      id
      additional_data
      book_id
      concept_id
      correct
      created_at
      passage_id
      seen
      concept {
        categories_denormalized
        created_at
        definition
        display_name
        obscurity
        updated_at
        root_appearances {
          definition_start_index
          definition_end_index
          start_index
          value
          root {
            display_name
            color
          }
        }
        concept_image_appearances(where: { primary: { _eq: true } }, order_by: { image: { quality: desc } }) {
          image {
            id
            s3_path
          }
        }
      }
    }
    group {
      id
      display_name
      users {
        id
        email
        created_at
        display_name
        classrooms(where: { archived: { _eq: false } }) {
          students {
            stars
          }
        }
      }
    }
  }
`

export const fetchUserQuery = (id: string, name = QueryName.FetchUser) => ({
  query: gql`
      ${USER_FIELDS}
      query User {
        users_by_pk(id: "${id}") {
          ...user_fields
        }
      }`,
  name,
})

export const fetchUsersStartingWithQuery = (email: string) => ({
  query: gql`
    query UsersStartingWith($email: String!) {
      users(where: { email: { _ilike: $email } }) {
        email
      }
    }
  `,
  variables: { email: `${email}%` },
})

export const incrementUserLevelQuery = (id: string) => ({
  query: gql`
    mutation IncrementUserLevel($id: String!) {
      update_users_by_pk(pk_columns: { id: $id }, _inc: { level: 1 }) {
        id
      }
    }
  `,
  variables: { id },
})

export const updateUserHasPremiumQuery = (id: string, has_premium: boolean) => ({
  query: gql`
    mutation UpdateUserHasPremium($id: String!, $has_premium: Boolean!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { has_premium: $has_premium }) {
        id
      }
    }
  `,
  variables: { id, has_premium },
})

export const addEncryptedPasswordQuery = (id: string, password: string) => ({
  query: gql`
    mutation AddEncryptedPassword($id: String!, $password: String!) {
      add_encrypted_password(id: $id, password: $password)
    }
  `,
  variables: { id, password },
})

export const updateUserDisplayNameQuery = (id: string, display_name: string) => ({
  query: gql`
    mutation UpdateUserDisplayName($id: String!, $display_name: String!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { display_name: $display_name }) {
        id
      }
    }
  `,
  variables: { id, display_name },
})

export const updateAdminConceptsQueueQuery = (id: string, concepts_queue_denormalized: string) => ({
  query: gql`
    mutation UpdateAdminConceptsQueue($id: String!, $concepts_queue_denormalized: String!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { concepts_queue_denormalized: $concepts_queue_denormalized }) {
        id
      }
    }
  `,
  variables: { id, concepts_queue_denormalized },
})

export const updateAdminPassagesQueueQuery = (id: string, passages_queue_denormalized: string) => ({
  query: gql`
    mutation UpdateAdminPassagesQueue($id: String!, $passages_queue_denormalized: String!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { passages_queue_denormalized: $passages_queue_denormalized }) {
        id
      }
    }
  `,
  variables: { id, passages_queue_denormalized },
})

export const dropStudentQuery = (id: string) => ({
  query: gql`
    mutation DropStudent($id: String!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { classroom_id: null }) {
        id
      }
    }
  `,
  variables: { id },
})

export const deleteStudentQuery = (id: string) => ({
  query: gql`
    mutation DeleteStudent($id: String!) {
      delete_user(id: $id)
    }
  `,
  variables: { id },
})

export const studentReportQuery = (id: string, name = QueryName.DownloadStudentReport) => ({
  query: gql`
    query StudentReport($id: String!) {
      users_by_pk(id: $id) {
        id
        accuracy
        concepts_discovered
        display_name
        stars
        experience(where: { concept_id: { _is_null: false }, correct: { _gt: 0 } }) {
          correct
          created_at
          seen
          concept {
            display_name
          }
        }
      }
    }
  `,
  variables: { id },
  name,
})

export const updatePasswordQuery = (id: string, password: string) => ({
  query: gql`
    mutation UpdateUserPassword($id: String!, $password: String!) {
      update_password(id: $id, password: $password)
    }
  `,
  variables: { id, password },
})

export const completedLevelQuery = (
  id: string,
  questions: any[],
  type: string,
  image_ids: number[],
  unlocked?: string,
  name = QueryName.CompletedLevel
) => ({
  query: gql`
    mutation CompletedLevel($id: String!, $questions: [SolvedQuestion!]!, $type: String!, $image_ids: [Int!]!, $unlocked: String) {
      completed_level(id: $id, questions: $questions, type: $type, image_ids: $image_ids, unlocked: $unlocked)
    }
  `,
  variables: { id, questions, type, unlocked, image_ids },
  name,
})

export const leaderboardsAroundUserQuery = (
  id: string,
  group: LeaderboardGroup,
  type: LeaderboardType,
  time: LeaderboardTime,
  name = QueryName.Leaderboards
) => ({
  query: gql`
    query LeaderboardsAroundUser($id: String!, $group: String!, $type: String!, $time: String!) {
      leaderboards_around_user(id: $id, group: $group, type: $type, time: $time) {
        count
        name
        positions {
          id
          name
          position
          description
          score
        }
      }
    }
  `,
  variables: { id, group, type, time },
  name,
})

export const leaderboardsAroundPositionQuery = (
  userId: string,
  position: number,
  group: LeaderboardGroup,
  type: LeaderboardType,
  time: LeaderboardTime,
  name = QueryName.Leaderboards
) => ({
  query: gql`
    query LeaderboardsAroundPosition($userId: String!, $group: String!, $type: String!, $position: Int!, $time: String!) {
      leaderboards_around_position(userId: $userId, group: $group, type: $type, position: $position, time: $time) {
        count
        name
        positions {
          id
          name
          position
          description
          score
        }
      }
    }
  `,
  variables: { userId, group, type, position, time },
  name,
})

export const leaderboardsPositionsQuery = (id: string) => ({
  query: gql`
    query LeaderboardsPositions($id: String!) {
      positions(id: $id) {
        position
        group
        type
        time
      }
    }
  `,
  variables: { id },
})

export const updateUserClassroomQuery = (id: string, classroom_id: number) => ({
  query: gql`
    mutation UpdateUserClassroom($id: String!, $classroom_id: Int!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { classroom_id: $classroom_id }) {
        id
      }
    }
  `,
  variables: { id, classroom_id },
})

export const updateUserSettingsQuery = (
  id: string,
  display_name: string,
  school: string,
  leaderboards_disabled: boolean,
  ai_report_section_enabled: boolean,
  sound_effects_enabled: boolean,
  pause_subscription_in_summer: boolean,
  settings: any
) => ({
  query: gql`
    mutation UpdateUserSettings(
      $id: String!
      $display_name: String!
      $school: String!
      $leaderboards_disabled: Boolean!
      $ai_report_section_enabled: Boolean!
      $sound_effects_enabled: Boolean!
      $pause_subscription_in_summer: Boolean!
      $settings: jsonb
    ) {
      update_users_by_pk(
        pk_columns: { id: $id }
        _set: {
          display_name: $display_name
          school: $school
          leaderboards_disabled: $leaderboards_disabled
          ai_report_section_enabled: $ai_report_section_enabled
          sound_effects_enabled: $sound_effects_enabled
          pause_subscription_in_summer: $pause_subscription_in_summer
          settings: $settings
        }
      ) {
        id
      }
    }
  `,
  variables: {
    id,
    display_name,
    school,
    leaderboards_disabled,
    ai_report_section_enabled,
    sound_effects_enabled,
    pause_subscription_in_summer,
    settings,
  },
  name: QueryName.UpdateUserSettings,
})

export const updateUserMetadataQuery = (id: string, grades: string, subrole: string, school: string | null, source: string | null) => ({
  query: gql`
    mutation UpdateUserMetadata($id: String!, $grades: String!, $subrole: String!, $school: String, $source: String) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { grades: $grades, subrole: $subrole, school: $school, source: $source }) {
        id
      }
    }
  `,
  variables: { id, grades, subrole, school, source },
})

export const insertUserSequenceQuery = (object: user_sequences_insert_input) => ({
  query: gql`
    mutation InsertUserSequence($object: user_sequences_insert_input!) {
      insert_user_sequences_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const createCheckoutSessionQuery = (id: string) => ({
  query: gql`
    mutation CreateCheckoutSession($id: String!) {
      create_checkout_session(id: $id)
    }
  `,
  variables: { id },
})

export const createCustomerPortalSessionQuery = (id: string) => ({
  query: gql`
    mutation CreateCustomerPortalSession($id: String!) {
      create_customer_portal_session(id: $id)
    }
  `,
  variables: { id },
})

export const updateUserRoleQuery = (id: string, role: string, needs_default_classroom: boolean, name = QueryName.UpdateUserRole) => ({
  query: gql`
    mutation UpdateUserRole($id: String!, $role: String!, $needs_default_classroom: Boolean!) {
      update_user_role(id: $id, role: $role, needs_default_classroom: $needs_default_classroom)
    }
  `,
  variables: { id, role, needs_default_classroom },
  name,
})

export const updateUserOtherQueuesQuery = (id: string, other_queues: any | null) => ({
  query: gql`
    mutation UpdateUserOtherQueues($id: String!, $other_queues: jsonb) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { other_queues: $other_queues }) {
        id
        other_queues
      }
    }
  `,
  variables: { id, other_queues },
})

export const updateUserIsIndividualQuery = (id: string, is_individual: boolean) => ({
  query: gql`
    mutation UpdateUserIsIndividual($id: String!, $is_individual: Boolean!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { is_individual: $is_individual }) {
        id
        is_individual
      }
    }
  `,
  variables: { id, is_individual },
})

export const updateUserGroupIdQuery = (id: string, group_id: number) => ({
  query: gql`
    mutation UpdateUserGroupId($id: String!, $group_id: Int!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { group_id: $group_id }) {
        id
        group_id
      }
    }
  `,
  variables: { id, group_id },
})

export const updateUserCurriculumIdQuery = (id: string, curriculum_id: number) => ({
  query: gql`
    mutation UpdateUserCurriculumId($id: String!, $curriculum_id: Int!) {
      update_users_by_pk(pk_columns: { id: $id }, _set: { curriculum_id: $curriculum_id }) {
        id
        curriculum_id
      }
    }
  `,
  variables: { id, curriculum_id },
})

export const insertPedagogueEventQuery = (object: pedagogue_events_insert_input) => ({
  query: gql`
    mutation InsertPedagogueEvent($object: pedagogue_events_insert_input!) {
      insert_pedagogue_events_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const hasPremiumQuery = (studentId: string) => ({
  query: gql`
    query has_premium($studentId: String!) {
      has_premium(id: $studentId)
    }
  `,
  variables: { studentId },
})

export const updateStarCountQuery = (is: string) => ({
  query: gql`
    mutation UpdateStarCount($is: String!) {
      update_star_count(id: $is)
    }
  `,
  variables: { is },
})

export const insertUserEventQuery = (object: user_events_insert_input) => ({
  query: gql`
    mutation InsertUserEvent($object: user_events_insert_input!) {
      insert_user_events_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const fetchUserEventsQuery = (role: string, excludeUserIds = CONFIG.FILTER_HOTJAR_TEACHER_IDS) => ({
  name: QueryName.FetchUserEvents,
  query: gql`
    query UserEvents($role: String!, $excludeUserIds: [String!]!) {
      user_events(
        where: {
          user: { role: { _eq: $role }, id: { _nin: $excludeUserIds } }
          _or: [{ user: { classroom: { teacher_id: { _nin: $excludeUserIds } } } }, { user: { classroom_id: { _is_null: true } } }]
        }
        order_by: { id: desc }
        limit: 300
      ) {
        id
        created_at
        updated_at
        user_event_type_id
        additional_data
        user_event_type {
          display_name
        }
        user {
          school
          level
          stars
          created_at
          has_premium
          email
          classroom {
            teacher {
              has_premium
              school
            }
          }
          display_name
        }
      }
    }
  `,
  variables: { role, excludeUserIds },
})

export const fetchRecentAndPremiumUsersQuery = (gt = moment().subtract(1, "month"), name = QueryName.FetchRecentAndPremiumUsers) => ({
  query: gql`
    query RecentAndPremiumUsers($gt: timestamptz!) {
      users(where: { role: { _eq: "teacher" }, school: { _is_null: false }, _or: [{ has_premium: { _eq: true } }, { created_at: { _gt: $gt } }] }) {
        id
        created_at
        display_name
        email
        grades
        has_premium
        school
        subrole
        updated_at

        games_aggregate(where: { created_at: { _gt: $gt } }) {
          aggregate {
            count
          }
        }

        classrooms(where: { archived: { _eq: false } }) {
          students_aggregate {
            aggregate {
              count
            }
          }

          students_with_progress: students_aggregate(where: { stars: { _gt: 0 } }) {
            aggregate {
              count
            }
          }

          assignments_aggregate(where: { created_at: { _gt: $gt } }) {
            aggregate {
              count
            }
          }
        }
      }
    }
  `,
  variables: { gt },
  name,
})
