import gql from "graphql-tag"

import { concept_image_appearances_insert_input, concepts_insert_input, root_appearances_insert_input } from "../../../types/globalTypes"
import { Grammar } from "../../components/concept/grammar"
import CONFIG from "../../config"
import { QueryName } from "../queryNames"

const CONCEPT_FRAGMENT = gql`
  fragment concept_fields on concepts {
    id
    categories_denormalized
    created_at
    definition
    display_name
    grammar
    is_basic
    obscurity
    updated_at
    concept_image_appearances(order_by: { image: { quality: desc_nulls_last } }) {
      id
      image {
        id
        s3_path
        quality
        caption
      }
    }
    questions {
      id
    }
    root_appearances {
      id
      definition_end_index
      definition_start_index
      root_id
      start_index
      value
      root {
        color
        display_name
      }
    }
  }
`

export const fetchConceptQuery = (display_name: string, curriculum_id: number) => ({
  query: gql`
    ${CONCEPT_FRAGMENT}
    query ConceptByValue($display_name: String!, $curriculum_id: Int!) {
      concepts(where: { display_name: { _eq: $display_name }, curriculum_id: { _eq: $curriculum_id } }) {
        ...concept_fields
      }
    }
  `,
  variables: { display_name, curriculum_id },
  name: QueryName.FetchConcept,
})

export const fetchConceptsQuery = (curriculum_id = CONFIG.CORE_CURRICULUM_ID) => ({
  query: gql`
    ${CONCEPT_FRAGMENT}
    query Concepts($curriculum_id: Int!) {
      concepts(order_by: { display_name: asc }, where: { curriculum_id: { _eq: $curriculum_id } }) {
        ...concept_fields
      }
    }
  `,
  variables: { curriculum_id },
  name: QueryName.FetchConcepts,
})

export const insertConceptQuery = (object: concepts_insert_input) => ({
  query: gql`
    ${CONCEPT_FRAGMENT}
    mutation InsertConcept($object: concepts_insert_input!) {
      insert_concepts_one(object: $object) {
        ...concept_fields
      }
    }
  `,
  variables: { object },
})

export const insertConceptsQuery = (objects: concepts_insert_input[]) => ({
  query: gql`
    ${CONCEPT_FRAGMENT}
    mutation InsertConcepts($objects: [concepts_insert_input!]!) {
      insert_concepts(objects: $objects) {
        returning {
          ...concept_fields
        }
      }
    }
  `,
  variables: { objects },
})

export const deleteConceptQuery = (id: number) => ({
  query: gql`
    mutation DeleteConcept($id: Int!) {
      delete_concepts_by_pk(id: $id) {
        id
      }
    }
  `,
  variables: { id },
})

export const deleteConceptImageAppearanceQuery = (id: number) => ({
  query: gql`
    mutation DeleteConceptImageAppearance($id: Int!) {
      delete_concept_image_appearances_by_pk(id: $id) {
        id
      }
    }
  `,
  variables: { id },
})

export const insertConceptImageAppearanceQuery = (object: concept_image_appearances_insert_input) => ({
  query: gql`
    mutation InsertConceptImageAppearance($object: concept_image_appearances_insert_input!) {
      insert_concept_image_appearances_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const insertConceptImageAppearancesQuery = (objects: concept_image_appearances_insert_input[]) => ({
  query: gql`
    mutation InsertConceptImageAppearances($objects: [concept_image_appearances_insert_input!]!) {
      insert_concept_image_appearances(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  variables: { objects },
})

export const updateConceptQuery = (id: number, obscurity: number | null, definition: string | null, grammar: Grammar | null) => ({
  query: gql`
    ${CONCEPT_FRAGMENT}
    mutation UpdateConcept($id: Int!, $definition: String, $obscurity: Int, $grammar: jsonb) {
      update_concepts_by_pk(pk_columns: { id: $id }, _set: { definition: $definition, obscurity: $obscurity, grammar: $grammar }) {
        ...concept_fields
      }
    }
  `,
  variables: { id, definition, obscurity, grammar },
})

export const fetchRootsQuery = () => ({
  query: gql`
    query Roots {
      roots {
        id
        display_name
        definition
        root_appearances {
          concept {
            display_name
            obscurity
            is_basic
          }
        }
      }
    }
  `,
})

export const insertRootAppearanceQuery = (object: root_appearances_insert_input) => ({
  query: gql`
    mutation InsertRootAppearance($object: root_appearances_insert_input!) {
      insert_root_appearances_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const deleteRootAppearanceQuery = (id: number) => ({
  query: gql`
    mutation DeleteRootAppearance($id: Int!) {
      delete_root_appearances_by_pk(id: $id) {
        id
      }
    }
  `,
  variables: { id },
})

export const fetchConceptPassageCountsQuery = () => ({
  query: gql`
    query ConceptPassageCounts {
      concepts(where: { root_appearances: { id: { _is_null: false } } }) {
        display_name
        questions_aggregate(where: { passage_id: { _is_null: false } }, distinct_on: passage_id) {
          aggregate {
            count
          }
        }
      }
    }
  `,
})
