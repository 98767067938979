import { navigate } from "gatsby"
import has from "lodash/has"
import { createSlice } from "@reduxjs/toolkit"

import CONFIG from "../../config"
import callHasura from "../callHasura"
import { OLOG, captureSentryError, orderElementsByArray, queryParams } from "../../lib/helpers"
import { QuestionsForIds } from "../queries/types/QuestionsForIds"
import { Sequences, Sequences_sequences } from "../queries/types/Sequences"
import { defaultSetLoading, defaultNetworkingFailure } from "./common"
import { fetchSequencesQuery, fetchQuestionsForIdsQuery, fetchQuestionsForUserQuery } from "../queries/sequence"
import { setNotificationAction, NotificationId } from "./notification"

import {
  QuestionsForUser_questions_for_user_content_QuestionContent_choices,
  QuestionsForUser_questions_for_user_content_QuestionContent,
  QuestionsForUser_questions_for_user_content_ReadContent,
  QuestionsForUser_questions_for_user_content_QuestionContent_prompt,
  QuestionsForUser_questions_for_user_content_QuestionContent_answer,
  QuestionsForUser_questions_for_user_content_QuestionContent_images,
  QuestionsForUser_questions_for_user,
  QuestionsForUser_questions_for_user_content_ReadContent_prompt,
} from "../queries/types/QuestionsForUser"

export enum QuestionType {
  ConceptToCharactersAllRoots = "Concept To Characters (all roots)",
  ConceptToCharactersOneCharacter = "Concept To Characters (one character)",
  ConceptToCharactersOneRoot = "Concept To Characters (one root)",
  ConceptToDefinition = "Concept To Definition",
  ConceptToDefinitionReverse = "Concept To Definition Reverse",
  ConceptToGender = "Concept To Gender",
  ConceptToPlural = "Concept To Plural",
  ConceptToProperty = "Concept To Property",
  ConceptToRoots = "Concept To Roots",
  FillInTheBlank = "Fill In The Blank",
  GrammarRole = "Grammar Role",
  PartOfSpeech = "Part Of Speech",
  ReadPassage = "Read Passage",
  RootToDefinition = "Root To Definition",
  TrueOrFalse = "True Or False",
}

export const ConceptToCharactersTypes = [
  QuestionType.ConceptToCharactersOneCharacter,
  QuestionType.ConceptToCharactersOneRoot,
  QuestionType.ConceptToCharactersAllRoots,
]

export const isQuestion = (content?: Content): content is QuestionContent => has(content, "answer") || has(content, "choices")

export type Content = QuestionsForUser_questions_for_user_content_QuestionContent | QuestionsForUser_questions_for_user_content_ReadContent
export type Question = QuestionsForUser_questions_for_user
export type QuestionContent = QuestionsForUser_questions_for_user_content_QuestionContent
export type ReadContent = QuestionsForUser_questions_for_user_content_ReadContent
export type Prompt =
  | QuestionsForUser_questions_for_user_content_QuestionContent_prompt
  | QuestionsForUser_questions_for_user_content_ReadContent_prompt
export type Answer = QuestionsForUser_questions_for_user_content_QuestionContent_answer
export type Choice = QuestionsForUser_questions_for_user_content_QuestionContent_choices
export type QuestionContentImage = QuestionsForUser_questions_for_user_content_QuestionContent_images

export interface SequenceState {
  sequences: Sequences_sequences[]
  questions: Question[]
}

export enum SequenceType {
  Multiplayer = "multiplayer",
  Train = "core",
  Test = "test",
  Demo = "demo",
}

const initialState: SequenceState = {
  sequences: [],
  questions: [],
}

const sequenceSlice = createSlice({
  name: "sequence",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,

    fetchSequencesSuccess: (state, { payload }) => {
      state.sequences = payload
    },

    unsetQuestions: (state) => {
      state.questions = []
    },

    fetchQuestionsSuccess: (state, { payload }: { payload: Question[] }) => {
      state.questions = payload
    },

    setQuestions: (state, { payload }: { payload: Question[] }) => {
      state.questions = payload
    },
  },
})

export const { setLoading, networkingFailure, fetchSequencesSuccess, fetchQuestionsSuccess, setQuestions, unsetQuestions } = sequenceSlice.actions

export const sequenceSelector = (state: any) => state.sequence

export default sequenceSlice.reducer

export function fetchSequencesAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: Sequences = await callHasura(accessToken, fetchSequencesQuery())

      if (result.sequences) {
        dispatch(fetchSequencesSuccess(result.sequences))
      } else {
        dispatch(networkingFailure())
      }
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function unsetQuestionsAction() {
  return async (dispatch: any) => {
    dispatch(unsetQuestions())
  }
}

interface PlayParams {
  type: string
  ids?: string
  id?: string
  r?: string // root id
  st?: string // seed type
  si?: string // seed id
}

const parseIds = (str: string): number[] =>
  str
    .split(",")
    .map((str) => parseInt(str, 10))
    .filter((n) => n)

export function fetchQuestionsAction(
  accessToken: string,
  curriculum_id: number = CONFIG.CORE_CURRICULUM_ID,
  userId?: string,
  multiplayerExtended = false
) {
  // @ts-ignore
  const params: PlayParams = queryParams()

  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      let questions: Question[] = []

      const isTrain = params.type === SequenceType.Train && userId
      const isTest = params.type === SequenceType.Test && params.ids
      const isMultiplayer = params.type === SequenceType.Multiplayer
      const isDemo = params.type === SequenceType.Demo

      if (isDemo) {
        questions = (
          await callHasura(
            accessToken,
            fetchQuestionsForUserQuery(curriculum_id, params.type, userId, undefined, params.st, parseInt(params.si || "", 10)),
            true
          )
        ).questions_for_user
      } else if (isTrain) {
        questions = (await callHasura(accessToken, fetchQuestionsForUserQuery(curriculum_id, params.type, userId), true)).questions_for_user
      } else if (isMultiplayer) {
        const rootId = multiplayerExtended ? undefined : parseInt(params.r!, 10)
        questions = (await callHasura(accessToken, fetchQuestionsForUserQuery(curriculum_id, params.type, userId, rootId), true)).questions_for_user
      } else if (isTest) {
        const ids = parseIds(params.ids!)
        const result: QuestionsForIds = await callHasura(accessToken, fetchQuestionsForIdsQuery(ids))
        questions = orderElementsByArray(result.questions, ids)
      } else {
        OLOG(`ERROR: unrecognizable play params ${JSON.stringify(params)}`, true)
      }

      if (questions.length) {
        dispatch(fetchQuestionsSuccess(questions))
      } else {
        dispatch(networkingFailure())
      }
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      navigate("/home")
      captureSentryError("Fetch questions error", { userId, type: params.type })
      dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      dispatch(networkingFailure())
    }
  }
}

export function setQuestionsAction(questions: Question[]) {
  return async (dispatch: any) => {
    dispatch(setQuestions(questions))
  }
}
