import { Role } from "./interfaces/role"

const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT || "development"
const IS_PRODUCTION = ENVIRONMENT === "production"
const IS_DEVELOPMENT = ENVIRONMENT === "development"

const SHOULD_TEST_USER = false

const TEST_USER = {
  oliver: { id: "google-oauth2|109072470665309799621", role: Role.Teacher },
  amanda: { id: "google-oauth2|109823126694726054887", role: Role.Teacher },
  test: { id: "auth0|65b1247c50fd0ecd7c4bf409", role: Role.Student },
}["test"]

export default {
  AUDIO_URL: "https://dwhq8v0z2dt4u.cloudfront.net/",
  AUTH0_AUDIENCE: process.env.GATSBY_AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: process.env.GATSBY_AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.GATSBY_AUTH0_DOMAIN,
  CHAT_GPT_API_KEY: process.env.GATSBY_CHAT_GPT_API_KEY,
  CORE_CURRICULUM_ID: 3,
  DEFAULT_PASSWORD: "1234567",
  DOMAIN: process.env.GATSBY_DOMAIN,
  ENVIRONMENT,
  FLASK_API_URL: process.env.GATSBY_FLASK_API_URL || "https://pyrogen.onrender.com",
  HASURA_API_URL: process.env.GATSBY_HASURA_API_URL,
  HASURA_GRAPHQL_ADMIN_SECRET: process.env.GATSBY_HASURA_GRAPHQL_ADMIN_SECRET,
  HEADER_HEIGHT: 60,
  INTERCOM_APP_ID: "xjl40id6",
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  LOCAL_API_URL: "http://localhost:3002/graphql",
  SHOULD_TEST_USER: IS_DEVELOPMENT && SHOULD_TEST_USER,
  SUPER_ADMIN_IDS: [
    "google-oauth2|109072470665309799621", // oliver
    "google-oauth2|107353559660707659496", // akiva
    // "auth0|6547a1d0c29e4f5d08ed7cf7", // ben
  ],
  FILTER_HOTJAR_TEACHER_IDS: [
    "google-oauth2|109072470665309799621", // oliver
    "auth0|63ab09ad856b8f2dee209c67", // oliver
    "google-oauth2|107353559660707659496", // akiva
    "auth0|61fb472d896f9c006828eedd", // akiva
    "auth0|61fb4b268d417f00739949f5", // akiva
    "auth0|61fb3fb4e1472a007122a988", // akiva
    "auth0|632a1f6acf040a204aeeb08e", // akiva
  ],
  STRIPE_PUBLISHABLE_KEY: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
  MAX_CLASSES_BASE: 3,
  BASE_WORDS: 100,
  BASE_PASSAGES: 100,
  TOTAL_WORDS: 3000,
  TOTAL_PASSAGES: 1000,
  MAX_CLASS_SIZE: 35,
  TOTAL_IMAGES: 1000,
  TEST_USER,
  TEST_CURRICULUM_ID: 5,
  VERBOSE: process.env.GATSBY_VERBOSE === "true",
  USERS_SLACK_CHANNEL_HOOK: "https://hooks.slack.com/services/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",
}
