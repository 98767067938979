import { createSlice } from "@reduxjs/toolkit"

import { sleep } from "../../lib/helpers"

const NOTIFICATION_DURATION_SECONDS = 4.4

export const NOTIFICATION_TRANSITION_SECONDS = 0.6

export enum NotificationId {
  AssignmentCreated,
  ClassCreated,
  ClassUpdated,
  ClassnameExists,
  ConceptsCreated,
  EnteredGameCodeInsteadOfJoinCode,
  GameCodeNotFound,
  ImageSaved,
  PassagesUploaded,
  JoinCodeNotFound,
  MustAnswerQuestion,
  NoResults,
  PassageArchived,
  PassagePunted,
  PassageRejected,
  PassageSaved,
  PasswordReset,
  ResetPasswordEmailSent,
  SettingsUpdated,
  SomethingBadHappened,
  StudentClassSwitched,
  TeachersInvited,
  UniquenessViolation,
}

interface Notification {
  id: NotificationId
  title: string
  color?: string
  isCancelable?: boolean
}

const notifications: Map<number, Notification> = new Map([
  [NotificationId.AssignmentCreated, { id: NotificationId.AssignmentCreated, title: "Assignment Created" }],
  [NotificationId.ClassCreated, { id: NotificationId.ClassCreated, title: "Class Created" }],
  [NotificationId.ClassUpdated, { id: NotificationId.ClassUpdated, title: "Class Updated" }],
  [NotificationId.ConceptsCreated, { id: NotificationId.ConceptsCreated, title: "Concept(s) Created" }],
  [NotificationId.ClassnameExists, { id: NotificationId.ClassnameExists, title: "Class Exists | Choose A New Class Name", color: "danger" }],
  [NotificationId.GameCodeNotFound, { id: NotificationId.GameCodeNotFound, title: "Game Code Not Found", color: "danger" }],
  [NotificationId.ImageSaved, { id: NotificationId.ImageSaved, title: "Image Saved" }],
  [NotificationId.JoinCodeNotFound, { id: NotificationId.JoinCodeNotFound, title: "Class Code Not Found", color: "danger" }],
  [NotificationId.MustAnswerQuestion, { id: NotificationId.MustAnswerQuestion, title: "Answer The Question To Continue" }],
  [NotificationId.NoResults, { id: NotificationId.NoResults, title: "No Results", color: "danger" }],
  [NotificationId.PassageArchived, { id: NotificationId.PassageArchived, title: "Passage Archived" }],
  [NotificationId.PassagesUploaded, { id: NotificationId.PassagesUploaded, title: "Passages Uploaded" }],
  [NotificationId.PassagePunted, { id: NotificationId.PassagePunted, title: "Passage Punted To End Of Queue" }],
  [NotificationId.PassageRejected, { id: NotificationId.PassageRejected, title: "Passage Rejected", color: "danger" }],
  [NotificationId.UniquenessViolation, { id: NotificationId.UniquenessViolation, title: "Uniqueness Violation", color: "danger" }],
  [NotificationId.PassageSaved, { id: NotificationId.PassageSaved, title: "Passage Saved" }],
  [NotificationId.PasswordReset, { id: NotificationId.PasswordReset, title: "Password Updated" }],
  [NotificationId.ResetPasswordEmailSent, { id: NotificationId.ResetPasswordEmailSent, title: "Reset Password Email Sent" }],
  [NotificationId.SettingsUpdated, { id: NotificationId.SettingsUpdated, title: "Settings Updated" }],
  [NotificationId.SomethingBadHappened, { id: NotificationId.SomethingBadHappened, title: "Something Bad Happened", color: "danger" }],
  [NotificationId.StudentClassSwitched, { id: NotificationId.StudentClassSwitched, title: "Switched Student Class" }],
  [NotificationId.TeachersInvited, { id: NotificationId.TeachersInvited, title: "Teacher(s) Invited" }],
  [
    NotificationId.EnteredGameCodeInsteadOfJoinCode,
    {
      id: NotificationId.EnteredGameCodeInsteadOfJoinCode,
      title: "You entered a 'game code' before joining a class. Please ask your teacher for your 'class code'.",
      color: "danger",
      isCancelable: true,
    },
  ],
])

export interface NotificationState {
  notification?: Notification
  notificationOpacity: number
}

const initialState: NotificationState = {
  notificationOpacity: 0,
}

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, { payload }) => {
      state.notification = payload
      state.notificationOpacity = 1
    },

    hideNotification: (state) => {
      state.notificationOpacity = 0
    },

    removeNotification: (state) => {
      state.notification = undefined
    },
  },
})

export const { setNotification, hideNotification, removeNotification } = notificationSlice.actions

export const notificationSelector = (state: any) => state.notification

export default notificationSlice.reducer

export function setNotificationAction(notificationId: NotificationId) {
  return async (dispatch: any) => {
    const notification = notifications.get(notificationId)
    dispatch(setNotification(notification))
    if (!notification?.isCancelable) {
      await sleep(NOTIFICATION_DURATION_SECONDS - NOTIFICATION_TRANSITION_SECONDS)
      dispatch(hideNotification())
      await sleep(NOTIFICATION_TRANSITION_SECONDS)
      dispatch(removeNotification())
    }
  }
}

export function unsetNotificationAction() {
  return async (dispatch: any) => {
    dispatch(hideNotification())
    await sleep(NOTIFICATION_TRANSITION_SECONDS)
    dispatch(removeNotification())
  }
}
