export enum QueryName {
  AnnotateText = "AnnotateText",
  ArchiveClassroom = "ArchiveClassroom",
  CompletedLevel = "CompletedLevel",
  DownloadStudentReport = "DownloadStudentReport",
  FetchConcept = "FetchConcept",
  FetchConcepts = "FetchConcepts",
  FetchImage = "FetchImage",
  FetchPassages = "FetchPassages",
  FetchUser = "FetchUser",
  FindGame = "FindGame",
  Games = "Games",
  InsertAssignment = "InsertAssignment",
  InsertClassroom = "InsertClassroom",
  InsertGame = "Insert Game",
  InsertPassages = "Insert Passages",
  InsertGamePosition = "Insert Game Position",
  JoinClass = "JoinClass",
  Leaderboards = "Leaderboards",
  LoginCards = "LoginCards",
  LoginsRaw = "LoginsRaw",
  MineImages = "MineImages",
  SaveImages = "SaveImages",
  UpdateAdminImagesQueue = "UpdateAdminImagesQueue",
  UpdatePassageMetadata = "UpdatePassageMetadata",
  UpdateUserRole = "UpdateUserRole",
  UpdateUserSettings = "UpdateUserSettings",
  FetchUserEvents = "FetchUserEvents",
  FetchRecentAndPremiumUsers = "FetchRecentAndPremiumUsers",
}
