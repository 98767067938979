import auth0 from "auth0-js"
import compact from "lodash/compact"
import get from "lodash/get"
import isString from "lodash/isString"
import { navigate } from "gatsby"

import CONFIG from "../config"
import callHasura from "../hasura/callHasura"
import { SignUpParams } from "../hasura/slices/user"
import { addEncryptedPasswordQuery, updateUserClassroomQuery } from "../hasura/queries/user"
import { captureSentryError, getWindow, OLOG, parseIdFrom } from "../lib/helpers"
import { defaultStudentErrorMessage } from "../hasura/slices/common"

const formatAuth0Error = (error: any) => {
  OLOG(error, true)

  if (isString(error.description)) {
    if (error.description.includes("email format validation failed")) {
      return "Please enter a valid email."
    }

    return error.description
  }

  if (get(error, "original.response.body.message")) {
    return `${error.original.response.body.message}. ${error.original.response.body.policy.replace("* ", "")}.`
  }

  return defaultStudentErrorMessage
}

export default class AuthService {
  auth: auth0.WebAuth
  dbConnection = "Username-Password-Authentication"
  dbCookieKey = "access_token"
  googleConnection = "google-oauth2"
  authScreens = [
    // when we setup a marketing site we can get rid of this
    "/signup",
    "/login",
    "/signup/student",
    "/signup/teacher",
    "/signup/individual",
  ]
  redirectUri = CONFIG.DOMAIN!
  responseType = "token id_token"
  scope = "read:order write:order"

  constructor() {
    this.auth = new auth0.WebAuth({
      audience: CONFIG.AUTH0_AUDIENCE,
      clientID: CONFIG.AUTH0_CLIENT_ID!,
      domain: CONFIG.AUTH0_DOMAIN!,
      responseType: this.responseType,
      scope: this.scope,
    })
  }

  checkSession(cb: (accessToken: string) => void) {
    console.log("AUTH: checking session")
    const pathname = getWindow(["location", "pathname"])
    const isOnAuthScreen = this.authScreens.includes(pathname.replace(/\/+$/, ""))
    const isTesting = getWindow(["location", "href"]).includes("?type=test&ids=")
    if (isTesting) {
      return
    }

    const accessToken = this.getAccessTokenCookie()
    if (accessToken) {
      if (isOnAuthScreen) navigate("/home")
      return cb(accessToken)
    }

    this.auth.checkSession({ redirectUri: this.redirectUri }, (error: any, authResult: any) => {
      if (error) {
        console.log(`AUTH: ${error?.code}`, error)
        if (isOnAuthScreen || CONFIG.SHOULD_TEST_USER) return

        navigate("/login")
      } else {
        console.log("AUTH: access token found")
        cb(authResult.accessToken)
      }
    })
  }

  getEmailFor(usernameOrEmail: string) {
    const isUsername = !usernameOrEmail?.includes("@")
    return isUsername ? `${usernameOrEmail.replace(/\s+/g, "")}+dummy@playwordcraft.com` : usernameOrEmail
  }

  deleteAccessTokenCookie() {
    const cookieString = `${this.dbCookieKey}=; ${CONFIG.DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    OLOG("deleting access token cookie...")
    document.cookie = cookieString
  }

  getAccessTokenCookie(): string | undefined {
    const value = `; ${document.cookie}`
    const parts = compact(value.split(`; access_token=`))

    if (parts.length === 2) {
      const accessToken = parts.pop()!.split(";").shift()
      console.log("AUTH: found access token cookie")
      return accessToken
    }
  }

  google(role?: string, classroomId?: number, cb?: (accessToken?: string, error?: string) => void) {
    const redirectUri = role ? `${this.redirectUri}?r=${role}` : this.redirectUri

    this.auth.popup.authorize(
      { connection: this.googleConnection, redirectUri, responseType: "token", domain: CONFIG.AUTH0_DOMAIN!, owp: true },
      async (error: any, result: any) => {
        if (error && cb) {
          cb(undefined, formatAuth0Error(error))
          return
        }

        const accessToken = result.accessToken
        this.setAccessTokenCookie(accessToken!)
        if (classroomId && accessToken) {
          const id = parseIdFrom(accessToken)

          if (id) {
            await callHasura("", updateUserClassroomQuery(id, classroomId))
          } else {
            captureSentryError(`no id found for access token ${accessToken}`)
          }
        }
        if (cb) cb(accessToken!)
      }
    )
  }

  logIn(email: string, password: string, cb: (accessToken?: string, error?: string) => void) {
    const params = { username: this.getEmailFor(email), password: password.trim(), realm: this.dbConnection }

    this.auth.client.login(params, (error: any, authResult: any) => {
      if (error) {
        cb(undefined, formatAuth0Error(error))
        return
      }

      const accessToken = authResult.accessToken
      this.setAccessTokenCookie(accessToken)
      cb(accessToken)
      navigate("/home")
    })
  }

  logOut() {
    this.deleteAccessTokenCookie()
    this.auth.logout({ returnTo: CONFIG.DOMAIN })
  }

  setAccessTokenCookie(accessToken: string) {
    const expiresInHours = 72
    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + expiresInHours)
    const cookieString = `${this.dbCookieKey}=${accessToken}; ${CONFIG.DOMAIN}; expires=${expirationDate.toUTCString()}`
    OLOG("AUTH: creating cookie...")
    document.cookie = cookieString
  }

  async signUpStudent(signUpParams: SignUpParams, classroomId: number, cb: (error?: string) => void) {
    const params = {
      email: signUpParams.email,
      password: signUpParams.password.trim(),
      connection: this.dbConnection,
      userMetadata: { role: "student", classroom_id: String(classroomId), display_name: signUpParams.displayName },
    }
    OLOG(`signup user with params: ${JSON.stringify(params)}`)

    this.auth.signupAndAuthorize(params, (error: any, data: any) => {
      if (error) {
        const msg = error.code === "user_exists" ? "Username taken." : "Username must contains letters or numbers only."
        captureSentryError("Sign up student failed", { code: error.code, email: params.email })
        return cb(msg)
      }

      const id = parseIdFrom(data.accessToken)
      if (id) callHasura(data.accessToken, addEncryptedPasswordQuery(id, signUpParams.password))

      cb()
    })
  }

  sendResetPasswordEmail(email: string) {
    this.auth.changePassword({ email, connection: this.dbConnection }, (error, _) => {
      if (error) {
        OLOG("TODO: - handle error", true)
      }
    })
  }

  signUp(
    email: string,
    password: string,
    role: string,
    isIndividual: boolean,
    classroom_id: string | null,
    cb: (accessToken?: string, error?: string) => void
  ) {
    const userMetadata: any = { role }
    if (isIndividual) userMetadata.is_individual = "true"
    const params: any = { email, password: password.trim(), connection: this.dbConnection, userMetadata }
    OLOG(`signup user with params: ${JSON.stringify(params)}`)

    if (classroom_id) params.userMetadata.classroom_id = classroom_id

    this.auth.signupAndAuthorize(params, (error: any, result: any) => {
      if (error) {
        captureSentryError("Sign up failed", { code: error.code, email })
        cb(undefined, formatAuth0Error(error))
        return
      }

      const accessToken = result.accessToken
      this.setAccessTokenCookie(accessToken)
      const id = parseIdFrom(accessToken)
      if (classroom_id && id) {
        callHasura("", addEncryptedPasswordQuery(id, password))
      }
      cb(accessToken)
    })
  }
}
