import React from "react"
import { Provider } from "react-redux"

import createStore from "./createStore"

const initialState = {}

const ReduxWrapper = ({ element }) => (
  <Provider store={createStore(initialState)}>{element}</Provider>
)

export default ReduxWrapper
