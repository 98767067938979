import CONFIG from "./config"
import { OLOG } from "./lib/helpers"

export default async (endpoint: string, method: string = "GET", body?: any | undefined) => {
  const url = `${CONFIG.FLASK_API_URL}${endpoint}`
  OLOG(`querying ${endpoint}`)

  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const params: any = { headers, method }
  if (body) params.body = JSON.stringify(body)

  try {
    const response = await fetch(url, params)
    const data = await response.json()

    OLOG(`${endpoint.split("?")[0]} SUCCESS`)
    OLOG(data)

    return data
  } catch (error: any) {
    OLOG(`${endpoint.split("?")[0]} FAILURE`)
    console.log(`ERROR: ${error}`)

    throw Error(error.message)
  }
}
