const ELEMENTS = [
  "hydrogen",
  "helium",
  "lithium",
  "boron",
  "carbon",
  "nitrogen",
  "oxygen",
  "fluorine",
  "neon",
  "sodium",
  "magnesium",
  "aluminum",
  "chlorine",
  "argon",
  "potassium",
  "calcium",
  "scandium",
  "titanium",
  "vanadium",
  "chromium",
  "manganese",
  "iron",
  "cobalt",
  "nickel",
  "copper",
  "zinc",
  "zirconium",
  "silver",
  "iodine",
  "krypton",
  "platinum",
  "gold",
  "mercury",
]

const ADJECTIVES = [
  "available",
  "popular",
  "basic",
  "various",
  "difficult",
  "several",
  "united",
  "historical",
  "emotional",
  "old",
  "political",
  "healthy",
  "financial",
  "medical",
  "traditional",
  "federal",
  "strong",
  "successful",
  "expensive",
  "intelligent",
  "happy",
  "responsible",
  "helpful",
  "recent",
  "willing",
  "wonderful",
  "impossible",
  "serious",
  "rare",
  "technical",
]

export const joinCodeFor = (input: string) => {
  const clean = input.toLowerCase().trim()
  const first = ADJECTIVES.find((a) => clean.startsWith(a))
  const middle = ELEMENTS.find((e) => clean.slice(first?.length).includes(e))
  const last = clean.match(/\d+$/)?.[0]
  if (first && middle && last) return `${first}-${middle}-${last}`

  return ""
}
