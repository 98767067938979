exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-game-tsx": () => import("./../../../src/pages/admin-game.tsx" /* webpackChunkName: "component---src-pages-admin-game-tsx" */),
  "component---src-pages-assignments-tsx": () => import("./../../../src/pages/assignments.tsx" /* webpackChunkName: "component---src-pages-assignments-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-classes-tsx": () => import("./../../../src/pages/classes.tsx" /* webpackChunkName: "component---src-pages-classes-tsx" */),
  "component---src-pages-completed-game-tsx": () => import("./../../../src/pages/completed-game.tsx" /* webpackChunkName: "component---src-pages-completed-game-tsx" */),
  "component---src-pages-concept-tsx": () => import("./../../../src/pages/concept.tsx" /* webpackChunkName: "component---src-pages-concept-tsx" */),
  "component---src-pages-crossword-tsx": () => import("./../../../src/pages/crossword.tsx" /* webpackChunkName: "component---src-pages-crossword-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-image-tsx": () => import("./../../../src/pages/image.tsx" /* webpackChunkName: "component---src-pages-image-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intermission-tsx": () => import("./../../../src/pages/intermission.tsx" /* webpackChunkName: "component---src-pages-intermission-tsx" */),
  "component---src-pages-leaderboards-tsx": () => import("./../../../src/pages/leaderboards.tsx" /* webpackChunkName: "component---src-pages-leaderboards-tsx" */),
  "component---src-pages-library-tsx": () => import("./../../../src/pages/library.tsx" /* webpackChunkName: "component---src-pages-library-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-passages-tsx": () => import("./../../../src/pages/passages.tsx" /* webpackChunkName: "component---src-pages-passages-tsx" */),
  "component---src-pages-play-tsx": () => import("./../../../src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */),
  "component---src-pages-premium-tsx": () => import("./../../../src/pages/premium.tsx" /* webpackChunkName: "component---src-pages-premium-tsx" */),
  "component---src-pages-resource-library-tsx": () => import("./../../../src/pages/resource-library.tsx" /* webpackChunkName: "component---src-pages-resource-library-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-setup-game-tsx": () => import("./../../../src/pages/setup-game.tsx" /* webpackChunkName: "component---src-pages-setup-game-tsx" */),
  "component---src-pages-signup-already-have-account-banner-tsx": () => import("./../../../src/pages/signup/alreadyHaveAccountBanner.tsx" /* webpackChunkName: "component---src-pages-signup-already-have-account-banner-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-signup-individual-tsx": () => import("./../../../src/pages/signup/individual.tsx" /* webpackChunkName: "component---src-pages-signup-individual-tsx" */),
  "component---src-pages-signup-new-account-tsx": () => import("./../../../src/pages/signup/newAccount.tsx" /* webpackChunkName: "component---src-pages-signup-new-account-tsx" */),
  "component---src-pages-signup-student-tsx": () => import("./../../../src/pages/signup/student.tsx" /* webpackChunkName: "component---src-pages-signup-student-tsx" */),
  "component---src-pages-signup-teacher-tsx": () => import("./../../../src/pages/signup/teacher.tsx" /* webpackChunkName: "component---src-pages-signup-teacher-tsx" */),
  "component---src-pages-tagging-tsx": () => import("./../../../src/pages/tagging.tsx" /* webpackChunkName: "component---src-pages-tagging-tsx" */),
  "component---src-pages-users-tsx": () => import("./../../../src/pages/users.tsx" /* webpackChunkName: "component---src-pages-users-tsx" */),
  "component---src-pages-waiting-game-tsx": () => import("./../../../src/pages/waiting-game.tsx" /* webpackChunkName: "component---src-pages-waiting-game-tsx" */)
}

