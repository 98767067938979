import gql from "graphql-tag"

import { classrooms_insert_input, groups_insert_input } from "../../../types/globalTypes"
import { joinCodeFor } from "../../lib/matchClassCode"
import { QueryName } from "../queryNames"

export const fetchClassroomForJoinCodeQuery = (join_code: string) => ({
  query: gql`
    query ClassroomForJoinCode($join_code: String!) {
      classrooms(where: { join_code: { _eq: $join_code } }) {
        id
        display_name
        teacher {
          display_name
        }
        students_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,
  variables: { join_code: joinCodeFor(join_code) },
})

export const fetchClassroomQuery = (id: number) => ({
  query: gql`
    query Classroom($id: Int!) {
      classrooms_by_pk(id: $id) {
        id
        created_at
        updated_at
        display_name
        join_code
        archived
        students {
          id
          accuracy
          concepts_discovered
          concepts_mastered
          display_name
          email
          historical_stats
          level
          stars
          total_correct
          total_seen
          user_sequences {
            started_at
            seconds
          }
        }
      }
    }
  `,
  variables: { id },
})

export const fetchGroupQuery = (id: number) => ({
  query: gql`
    query Group($id: Int!) {
      groups_by_pk(id: $id) {
        id
        display_name
        users {
          id
          display_name
        }
      }
    }
  `,
  variables: { id },
})

export const insertGroupQuery = (object: groups_insert_input) => ({
  query: gql`
    mutation InsertGroup($object: groups_insert_input!) {
      insert_groups_one(object: $object) {
        id
        display_name
      }
    }
  `,
  variables: { object },
})

export const updateClassroomDisplayNameQuery = (id: number, display_name: string) => ({
  query: gql`
    mutation UpdateClassroomDisplayName($id: Int!, $display_name: String!) {
      update_classrooms_by_pk(pk_columns: { id: $id }, _set: { display_name: $display_name }) {
        id
      }
    }
  `,
  variables: { id, display_name },
})

export const insertClassroomQuery = (object: classrooms_insert_input) => ({
  query: gql`
    mutation InsertClassroom($object: classrooms_insert_input!) {
      insert_classrooms_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
})

export const joinCodeQuery = () => ({
  query: gql`
    query JoinCode {
      join_code
    }
  `,
})

export const loginCardsQuery = (id: number, name = QueryName.LoginCards) => ({
  query: gql`
    query LoginCards($id: Int!) {
      login_cards(id: $id)
    }
  `,
  variables: { id },
  name,
})

export const loginsRawQuery = (id: number, name = QueryName.LoginsRaw) => ({
  query: gql`
    query LoginsRaw($id: Int!) {
      logins_raw(id: $id) {
        displayName
        email
        username
        google
        hasDefaultPassword
        password
      }
    }
  `,
  variables: { id },
  name,
})

export const archiveClassroomQuery = (id: number, archived: boolean, name = QueryName.ArchiveClassroom) => ({
  query: gql`
    mutation ArchiveClassroom($id: Int!, $archived: Boolean!) {
      update_classrooms_by_pk(_set: { archived: $archived }, pk_columns: { id: $id }) {
        id
      }
    }
  `,
  variables: { id, archived },
  name,
})

export const inviteTeachersQuery = (id: number, display_name: string, group_name: string, emails: string[], message?: string) => ({
  query: gql`
    mutation InviteTeachers($id: Int!, $display_name: String!, $group_name: String!, $emails: [String!]!, $message: String) {
      invite_teachers(id: $id, display_name: $display_name, group_name: $group_name, emails: $emails, message: $message)
    }
  `,
  variables: { id, display_name, group_name, emails, message },
})

export const fetchPresignedUrlForReportQuery = (key: string, bucket: string) => ({
  query: gql`
    query PresignedUrlForReport($key: String!, $bucket: String!) {
      presigned_url(key: $key, bucket: $bucket)
    }
  `,
  variables: { key, bucket },
})
