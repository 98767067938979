// @ts-ignore
import { DocumentNode } from "apollo-link"
import get from "lodash/get"

import AuthService from "../services/auth"
import CONFIG from "../config"
import { OLOG, captureSentryError, queryName } from "../lib/helpers"

export interface Query {
  query: DocumentNode
  variables?: any
  name?: string
}

export enum HasuraError {
  UniquenessViolation,
  Default,
}

export const hasuraErrorType = (error: any) => {
  const s = String(error)
  if (s.includes("Uniqueness violation")) {
    return HasuraError.UniquenessViolation
  } else {
    return HasuraError.Default
  }
}

export default async (accessToken: string, query: Query, local = false) => {
  OLOG(`querying ${queryName(query)}${query.variables ? ` with variables ${JSON.stringify(query.variables)}` : ""}...`)

  const url = local && CONFIG.IS_DEVELOPMENT ? CONFIG.LOCAL_API_URL : CONFIG.HASURA_API_URL!

  const body = JSON.stringify({
    query: query.query.loc?.source.body,
    variables: query.variables,
  })

  const headers: any =
    accessToken === "" || CONFIG.SHOULD_TEST_USER
      ? {
          "content-type": "application/json",
          "x-hasura-role": "admin",
          "x-hasura-admin-secret": CONFIG.HASURA_GRAPHQL_ADMIN_SECRET,
        }
      : {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }

  const start = new Date()

  const response = await fetch(url, {
    body,
    headers,
    method: "POST",
  })

  const result = await response.json()

  // @ts-ignore
  const seconds = Math.abs(start - new Date()) / 1000

  if (result.errors) {
    const error = result.errors[0]
    const msg = `${queryName(query)} ERROR ${error.message} ${seconds}sec: ${JSON.stringify(query.variables)}`
    OLOG(msg)

    if (get(error, "extensions.code") === "invalid-jwt") {
      console.log("AUTH: src/hasura/callHasura.ts logout")
      new AuthService().logOut()
    } else {
      captureSentryError(`${queryName(query)} ${error.message}`, { variables: JSON.stringify(query.variables) })
    }

    throw Error(error.message)
  } else {
    OLOG(`${queryName(query)} SUCCESS ${seconds}sec`)
    OLOG(result.data)
    return result.data
  }
}
