import { createSlice } from "@reduxjs/toolkit"

import callHasura from "../callHasura"
import { choice_sets_insert_input } from "../../../types/globalTypes"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import { insertChoiceSetQuery, updateChoiceSetQuery } from "../queries/choiceSet"

export interface ChoiceSetState {
  isQuerying: any
}

const initialState: ChoiceSetState = {
  isQuerying: {},
}

const choiceSetSlice = createSlice({
  name: "choiceSet",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,
  },
})

export const { setLoading, networkingFailure, networkingSuccess } = choiceSetSlice.actions

export const choiceSetSelector = (state: any) => state.choiceSet

export default choiceSetSlice.reducer

export function insertChoiceSetAction(accessToken: string, choiceSet: choice_sets_insert_input) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertChoiceSetQuery(choiceSet))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateChoiceSetAction(accessToken: string, id: number, concepts: string[]) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateChoiceSetQuery(id, concepts))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
