import React from "react"

import { navigate } from "gatsby-link"
import { Button } from "reactstrap"

// @ts-ignore
export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="vw-100 vh-100 d-flex flex-column align-items-center justify-content-center text-center">
      <p className="text-xl m-0">Something bad happened.</p>

      <p className="text-m m-3">Our team has been alerted and we will work on a fix promptly.</p>

      <Button
        onClick={() => {
          navigate("/")
          resetErrorBoundary()
        }}
      >
        Return Home
      </Button>
    </div>
  )
}
