import moment from "moment"
import { createSlice } from "@reduxjs/toolkit"
import sortBy from "lodash/sortBy"

import callHasura from "../callHasura"
import { NotificationId, setNotificationAction } from "./notification"
import { assignments_insert_input } from "../../../types/globalTypes"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import { deleteAssignmentQuery, insertAssignmentQuery, updateAssignmentQuery } from "../queries/assignment"
import { fetchUserAction } from "./user"
import { formatDate, pluralize } from "../../lib/helpers"

import {
  User_users_by_pk,
  User_users_by_pk_classrooms,
  User_users_by_pk_classrooms_assignments,
  User_users_by_pk_classrooms_students,
} from "../queries/types/User"

export interface AssignmentState {
  isQuerying: any
  createAssignmentClassroomId?: number | string
  createAssignmentBookId?: number
}

export enum AssignmentSort {
  Alphabetical,
  Completed,
  Uncompleted,
}

const initialState: AssignmentState = {
  isQuerying: {},
}

export const assignmentDescription = (assignment: User_users_by_pk_classrooms_assignments) =>
  `${assignment.stars ? pluralize("star", assignment.stars) : assignment.book?.title} ${assignment.is_complete ? "completed on" : "due"} ${formatDate(
    assignment.due_date
  )}`

export const progressForBookAssignment = (
  assignment: User_users_by_pk_classrooms_assignments,
  student: User_users_by_pk_classrooms_students | User_users_by_pk
) => {
  const runs = student.experience.find((s) => s.book_id === assignment.book?.id)?.additional_data["runs"] || []
  const progress = Math.max(
    ...runs.filter((run: any) => moment(run.completed_at).isAfter(moment(assignment.created_at))).map((run: any) => (100 * run.correct) / run.seen)
  )
  return { delta: progress, progress }
}

export const progressForStarsAssignment = (
  assignment: User_users_by_pk_classrooms_assignments,
  student: User_users_by_pk_classrooms_students | User_users_by_pk
) => {
  const data = assignment.data.find((a: any) => a.id === student.id)
  const start = data?.start || 0
  const delta = (data?.end || student.stars) - start
  const progress = (100 * delta) / assignment.stars!
  return { progress, delta }
}

export const progressForAssignment = (
  assignment: User_users_by_pk_classrooms_assignments,
  student: User_users_by_pk_classrooms_students | User_users_by_pk
) => (assignment.book ? progressForBookAssignment(assignment, student) : progressForStarsAssignment(assignment, student))

export const assignmentIsComplete = (
  assignment: User_users_by_pk_classrooms_assignments,
  student: User_users_by_pk_classrooms_students | User_users_by_pk
) => progressForAssignment(assignment, student).progress >= (assignment.book ? 80 : 100)

export const assignmentStats = (classroom: User_users_by_pk_classrooms, assignment: User_users_by_pk_classrooms_assignments, sort: AssignmentSort) =>
  sortBy(
    classroom.students.map((student) => {
      const { progress, delta } = assignment.book?.id
        ? progressForBookAssignment(assignment, student)
        : progressForStarsAssignment(assignment, student)
      return { display_name: student.display_name, progress, delta, goal: assignment.stars }
    }),
    (p: any) => (sort === AssignmentSort.Alphabetical ? p.progress : sort === AssignmentSort.Completed ? -p.progress : p.progress)
  )

const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    createAssignmentClassroomId: (state, { payload }: { payload: { classId?: number | string; bookId?: number } }) => {
      state.createAssignmentClassroomId = payload.classId
      state.createAssignmentBookId = payload.bookId
    },
  },
})

export const { setLoading, networkingFailure, networkingSuccess, createAssignmentClassroomId } = assignmentSlice.actions

export const assignmentSelector = (state: any) => state.assignment

export default assignmentSlice.reducer

export function createAssignmentClassroomIdAction(classId?: number | string, bookId?: number) {
  return async (dispatch: any) => {
    dispatch(createAssignmentClassroomId({ classId, bookId }))
  }
}

export function insertAssignmentAction(accessToken: string, object: assignments_insert_input, teacher_id: string) {
  return async (dispatch: any) => {
    const query = insertAssignmentQuery(object)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      await dispatch(fetchUserAction(accessToken, teacher_id))
      dispatch(setNotificationAction(NotificationId.AssignmentCreated))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      dispatch(networkingFailure([query.name, error]))
    }
  }
}

export function deleteAssignmentAction(accessToken: string, id: number, teacherId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteAssignmentQuery(id))
      dispatch(fetchUserAction(accessToken, teacherId))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateAssignmentAction(accessToken: string, id: number, stars: number, due_date: string, teacherId: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, updateAssignmentQuery(id, stars, due_date))
      dispatch(fetchUserAction(accessToken, teacherId))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
