import cloneDeep from "lodash/cloneDeep"
import moment from "moment"
import compact from "lodash/compact"
import sampleSize from "lodash/sampleSize"
import sortBy from "lodash/sortBy"
import without from "lodash/without"
import { createSlice } from "@reduxjs/toolkit"
import { navigate } from "gatsby"

import callFlask from "../../callFlask"
import callHasura from "../callHasura"
import { ChoiceSets_choice_sets } from "../queries/types/ChoiceSets"
import { Concepts_concepts } from "../queries/types/Concepts"
import { OLOG, searchQueryParams } from "../../lib/helpers"
import { PassageByPk_passages_by_pk } from "../queries/types/PassageByPk"
import { PassagesForConcept } from "../queries/types/PassagesForConcept"
import { PassagesForIds_passages } from "../queries/types/PassagesForIds"
import { PassagesForLibrary } from "../queries/types/PassagesForLibrary"
import { PassagesForUser, PassagesForUser_passages } from "../queries/types/PassagesForUser"
import { QueryName } from "../queryNames"
import { UnenrichedPassageConcepts, UnenrichedPassageConcepts_passages } from "../queries/types/UnenrichedPassageConcepts"
import { UnenrichedPassagesForAdmin, UnenrichedPassagesForAdmin_passages } from "../queries/types/UnenrichedPassagesForAdmin"
import { UnenrichedPassagesForIds, UnenrichedPassagesForIds_passages } from "../queries/types/UnenrichedPassagesForIds"
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from "./common"
import { fetchChoiceSetsQuery } from "../queries/choiceSet"
import { InsertPassages } from "../queries/types/InsertPassages"
import { fetchUserAction, updateAdminPassagesQueueAction } from "./user"
import { NotificationId, setNotificationAction } from "./notification"

import {
  deletePassageQuery,
  fetchPassagesForConceptQuery,
  fetchPassagesForLibraryQuery,
  fetchPassagesForUserQuery,
  fetchUnenrichedPassageConceptsQuery,
  fetchUnenrichedPassagesForAdminQuery,
  fetchUnenrichedPassagesForIdsQuery,
  insertPassagesQuery,
  updatePassageConceptsQuery,
  updatePassageCustomQuestionsQuery,
  updatePassageIsCompleteQuery,
  updatePassageMetadataQuery,
  updatePassageQuery,
  updatePassageStatusQuery,
} from "../queries/passage"

export interface Annotated {
  objectIndexes?: number[]
  relationIndexes?: number[]
  subjectIndexes?: number[]
  tokens: Token[]
  triples: Triple[]
  text: string
  pretext?: string
  posttext?: string
}

export interface Triple {
  subjectIndexes: number[]
  relationIndexes: number[]
  objectIndexes: number[]
}

export interface RedHerringSet {
  values: string[]
  choiceSetId?: number
  value: string
}

export interface Token {
  acceptedRedHerrings?: string[]
  index: number
  lemma: string
  plural?: string
  pos: POS
  redHerringSets?: RedHerringSet[]
  singular?: string
  stanza_pos: string
  value: string
}

export enum POS {
  Noun = "noun",
  Adjective = "adjective",
  Verb = "verb",
}

export type UnenrichedPassage = UnenrichedPassagesForAdmin_passages | UnenrichedPassagesForIds_passages
export type Passage = PassagesForIds_passages | PassagesForUser_passages

export const isPassage = (entity: Passage | any): entity is Passage => (entity as Passage).illustrated_passages !== undefined

export interface PassageState {
  annotated?: Annotated
  isQuerying: any
  passage?: PassageByPk_passages_by_pk
  passagesForAdmin?: Passage[]
  passagesForConcept?: Passage[]
  passagesForUser?: PassagesForUser_passages[]
  unenrichedPassages?: UnenrichedPassage[]
  unenrichedPassageConcepts?: UnenrichedPassageConcepts_passages[]
}

const initialState: PassageState = {
  isQuerying: {},
}

const passageSlice = createSlice({
  name: "passage",
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchPassageSuccess: (state, { payload }: { payload: PassageByPk_passages_by_pk }) => {
      state.passage = payload
    },

    removePassage: (state) => {
      state.passage = undefined
    },

    unsetPassagesForConcept: (state) => {
      state.passagesForConcept = undefined
    },

    fetchPassagesForUserSuccess: (state, { payload }: { payload: PassagesForUser_passages[] }) => {
      state.passagesForUser = payload
    },

    fetchPassagesForLibrarySuccess: (state, { payload }: { payload: Passage[] }) => {
      state.passagesForAdmin = payload
    },

    fetchPassagesForConceptSuccess: (state, { payload }: { payload: Passage[] }) => {
      state.passagesForConcept = payload
    },

    fetchUnenrichedPassagesForAdminSuccess: (state, { payload }: { payload: UnenrichedPassage[] | undefined }) => {
      state.unenrichedPassages = payload
    },

    annotateTextSuccess: (state, { payload }: { payload: Annotated | undefined }) => {
      state.annotated = payload
    },

    fetchUnenrichedPassageConceptsSuccess: (state, { payload }: { payload: UnenrichedPassageConcepts_passages[] }) => {
      state.unenrichedPassageConcepts = payload
    },
  },
})

export const {
  annotateTextSuccess,
  fetchPassageSuccess,
  fetchPassagesForLibrarySuccess,
  fetchPassagesForUserSuccess,
  fetchUnenrichedPassagesForAdminSuccess,
  fetchUnenrichedPassageConceptsSuccess,
  fetchPassagesForConceptSuccess,
  networkingFailure,
  unsetPassagesForConcept,
  networkingSuccess,
  removePassage,
  setLoading,
} = passageSlice.actions

export const passageSelector = (state: any) => state.passage

export default passageSlice.reducer

export function addChoiceSetsToAnnotatedAction(annotated: Annotated, concepts: Concepts_concepts[] = [], curriculum_id: number) {
  return async (dispatch: any) => {
    const cloned = cloneDeep(annotated)
    const { choice_sets }: { choice_sets: ChoiceSets_choice_sets[] } = await callHasura("", fetchChoiceSetsQuery(curriculum_id))

    cloned.tokens.forEach((token) => {
      const rootIds = concepts.find((c) => c.display_name === token.lemma)?.root_appearances.map((r) => r.root_id) || []
      const sharesRoot = sampleSize(
        concepts
          .filter((c) => c.display_name !== token.lemma && c.root_appearances.map((r) => r.root_id).some((id) => rootIds.includes(id)))
          .map((c) => c.display_name),
        25
      ).sort()
      const choiceSets = choice_sets.filter((c) => c.concepts_denormalized.split(",").includes(token.lemma))

      if (!choiceSets.length && !sharesRoot.length) return

      token.redHerringSets = choiceSets.map((choiceSet) => ({
        choiceSetId: choiceSet.id,
        values: without(choiceSet.concepts_denormalized.split(","), token.lemma),
        value: token.lemma,
      }))

      if (sharesRoot.length) {
        token.redHerringSets.push({ value: token.lemma, values: sharesRoot })
      }

      token.acceptedRedHerrings = []
    })

    dispatch(annotateTextSuccess(cloned))
  }
}

export function annotateTextAction(text: string, concepts: Concepts_concepts[] = [], curriculum_id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading(QueryName.AnnotateText))
    dispatch(annotateTextSuccess(undefined))
    const annotated: Annotated = await callFlask("/mine", "POST", { text })
    dispatch(addChoiceSetsToAnnotatedAction(annotated, concepts, curriculum_id))
    dispatch(networkingSuccess(QueryName.AnnotateText))
  }
}

export function fetchPassagesForUserAction(accessToken: string, id: string) {
  return async (dispatch: any) => {
    dispatch(removePassage())
    if (!id) return

    try {
      const result: PassagesForUser = await callHasura(accessToken, fetchPassagesForUserQuery(id))

      if (result.passages) {
        dispatch(fetchPassagesForUserSuccess(result.passages))
      } else {
        dispatch(networkingFailure())
      }
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function fetchUnenrichedPassageConceptsAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const result: UnenrichedPassageConcepts = await callHasura(accessToken, fetchUnenrichedPassageConceptsQuery())
      dispatch(fetchUnenrichedPassageConceptsSuccess(result.passages))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function fetchPassagesForLibraryAction(accessToken: string, curriculum_id: number) {
  return async (dispatch: any) => {
    const query = fetchPassagesForLibraryQuery(curriculum_id)
    dispatch(setLoading(query.name))

    try {
      const result: PassagesForLibrary = await callHasura(accessToken, query)
      dispatch(fetchPassagesForLibrarySuccess(result.passages))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function fetchPassagesForConceptAction(concept: string) {
  return async (dispatch: any) => {
    try {
      const result: PassagesForConcept = await callHasura("", fetchPassagesForConceptQuery(concept))
      if (!result.passages.length) navigate(-1)
      dispatch(fetchPassagesForConceptSuccess(result.passages))
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function insertPassagesAction(accessToken: string, passages: string[], userId: string, currentPassageQueue: number[], curriculum_id: number) {
  return async (dispatch: any) => {
    const passageObjects = passages.map((text) => ({
      curriculum_id,
      original_text_sentence_tokenized: [text],
      source: `manual upload ${moment().format("LLLL")}`,
    }))
    const query = insertPassagesQuery(passageObjects)

    try {
      dispatch(setLoading(query.name))
      const result: InsertPassages = await callHasura(accessToken, query)
      const ids = result.insert_passages?.returning.map((p) => p.id) || []
      const updatedPassageQueue = ids.concat(...currentPassageQueue)
      await dispatch(updateAdminPassagesQueueAction(accessToken, userId, updatedPassageQueue.join(",")))
      await dispatch(fetchUserAction(accessToken, userId))
      dispatch(setNotificationAction(NotificationId.PassagesUploaded))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([error, query.name]))
    }
  }
}

export function fetchUnenrichedPassagesForAdminAction(accessToken: string, concept?: string) {
  return async (dispatch: any) => {
    const query = fetchUnenrichedPassagesForAdminQuery(concept)
    dispatch(setLoading(query.name))

    try {
      const result: UnenrichedPassagesForAdmin | UnenrichedPassagesForIds = await callHasura(accessToken, query)
      const passages = result.passages.filter((p) => !p.annotated)
      dispatch(fetchUnenrichedPassagesForAdminSuccess(passages))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function fetchPassagesForIdsAction(accessToken: string) {
  return async (dispatch: any) => {
    const ids = compact((searchQueryParams("ids") || searchQueryParams("id"))?.split(",").map((s) => parseInt(s, 10)))
    const query = fetchUnenrichedPassagesForIdsQuery(ids)
    dispatch(setLoading(query.name))

    try {
      const result: UnenrichedPassagesForIds = await callHasura(accessToken, query)
      const passages = sortBy(result.passages, (p) => ids.indexOf(p.id))
      dispatch(fetchUnenrichedPassagesForAdminSuccess(passages))
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function unsetUnenrichedPassagesForAdminAction() {
  return async (dispatch: any) => {
    dispatch(fetchUnenrichedPassagesForAdminSuccess())
  }
}

export function setAnnotatedAction(annotated?: Annotated) {
  return async (dispatch: any) => {
    dispatch(annotateTextSuccess(annotated))
  }
}

export function unsetPassagesForConceptAction() {
  return async (dispatch: any) => {
    dispatch(unsetPassagesForConcept())
  }
}

export function updatePassageAction(annotated: Annotated, userId: string, id: number) {
  return async () => {
    await callHasura("", updatePassageQuery(annotated, userId, id))
  }
}

export function updatePassageStatusAction(accessToken: string, id: number, status: string) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updatePassageStatusQuery(id, status))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updatePassageIsCompleteAction(accessToken: string, id: number, is_complete: boolean) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updatePassageIsCompleteQuery(id, is_complete))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updatePassageCustomQuestionsAction(accessToken: string, id: number, custom_questions: any) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updatePassageCustomQuestionsQuery(id, custom_questions))
      dispatch(setNotificationAction(NotificationId.PassageSaved))
      dispatch(networkingSuccess())
    } catch (error) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function updatePassageMetadataAction(accessToken: string, id: number, quality: number | null, difficulty: number | null) {
  return async (dispatch: any) => {
    const query = updatePassageMetadataQuery(id, quality, difficulty)
    dispatch(setLoading(query.name))

    try {
      await callHasura(accessToken, query)
      dispatch(networkingSuccess(query.name))
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure([query.name, error.message]))
    }
  }
}

export function updatePassageConceptsAction(accessToken: string, id: number, concepts_denormalized: string) {
  return async (dispatch: any) => {
    const query = updatePassageConceptsQuery(id, concepts_denormalized)

    try {
      await callHasura(accessToken, query)
      dispatch(networkingSuccess())
    } catch (error: any) {
      OLOG(`ERROR ${error}`, true)
      dispatch(networkingFailure())
    }
  }
}

export function deletePassageAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deletePassageQuery(id))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
