import gql from "graphql-tag"

import CONFIG from "../../config"

export const fetchPedagoguesQuery = () => ({
  query: gql`
    query Pedagogues {
      users(where: { passages_queue_denormalized: { _is_null: false } }) {
        passages_queue_denormalized
      }
    }
  `,
})

export const fetchClassroomExperienceQuery = (classroom_id: number) => ({
  query: gql`
    query ClassroomExperience($classroom_id: Int!) {
      concepts(where: { root_appearances: { id: { _is_null: false } }, curriculum_id: { _eq: 3 } }) {
        id
        display_name
        root_appearances {
          root {
            display_name
          }
        }
      }

      experience(where: { user: { classroom_id: { _eq: $classroom_id } } }) {
        concept_id
        seen
        correct
      }
    }
  `,
  variables: { classroom_id },
})

export const fetchEntityCountsQuery = (curriculum_id = CONFIG.CORE_CURRICULUM_ID) => ({
  query: gql`
    query EntityCounts($curriculum_id: Int!) {
      passages_aggregate(where: { curriculum_id: { _eq: $curriculum_id }, questions: { id: { _is_null: false } } }) {
        aggregate {
          count
        }
      }

      images_aggregate(
        where: { concept_image_appearances: { concept: { curriculum_id: { _eq: $curriculum_id }, questions: { id: { _is_null: false } } } } }
      ) {
        aggregate {
          count
        }
      }

      concepts_aggregate(where: { curriculum_id: { _eq: $curriculum_id }, questions: { id: { _is_null: false } } }) {
        aggregate {
          count
        }
      }
    }
  `,
  variables: { curriculum_id },
})

export const fetchCurriculumsQuery = () => ({
  query: gql`
    query Curriculums {
      curriculums {
        id
        display_name
      }
    }
  `,
})

export const fetchRandomSequenceQuery = (curriculum_id: number) => ({
  query: gql`
    query RandomSequence($curriculum_id: Int!) {
      questions(where: { curriculum_id: { _eq: $curriculum_id } }) {
        id
      }
    }
  `,
  variables: { curriculum_id },
})
