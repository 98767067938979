import React from "react"
import { ApolloProvider } from "@apollo/client/react"
import { ErrorBoundary } from "react-error-boundary"
import { IntercomProvider } from "react-use-intercom"

import CONFIG from "./src/config"
import ReduxWrapper from "./src/ReduxWrapper"
import apolloClient from "./src/apollo"
import { ErrorFallback } from "./src/components/errorFallback"

const errorHandler = (error, _) => {
  // @ts-ignore
  if (window.Sentry) window.Sentry.captureException(error)
}

export const wrapRootElement = (props) => {
  return (
    <IntercomProvider appId={CONFIG.INTERCOM_APP_ID}>
      <ApolloProvider client={apolloClient}>
        <ErrorBoundary onError={errorHandler} FallbackComponent={ErrorFallback}>
          <ReduxWrapper {...props} />
        </ErrorBoundary>
      </ApolloProvider>
    </IntercomProvider>
  )
}
