import gql from "graphql-tag"
import CONFIG from "../../config"

export const fetchSequencesQuery = (curriculum_id: number = CONFIG.CORE_CURRICULUM_ID) => ({
  query: gql`
    query Sequences($curriculum_id: Int!) {
      sequences(where: { curriculum_id: { _eq: $curriculum_id } }, order_by: { order_index: asc }) {
        id
        display_name
        order_index
        sequence_items {
          concept {
            display_name
            definition
            root_appearances {
              definition_start_index
              definition_end_index
              start_index
              value
              root {
                display_name
                definition
              }
            }
            concept_image_appearances {
              image {
                s3_path
              }
            }
          }
        }
      }
    }
  `,
  variables: { curriculum_id },
})

export const fetchQuestionsForIdsQuery = (ids: number[]) => ({
  query: gql`
    query QuestionsForIds($ids: [Int!]!) {
      questions(where: { id: { _in: $ids } }) {
        id
        concept_id
        content
        passage_id
        read_mode
        type
      }
    }
  `,
  variables: { ids },
})

export const fetchQuestionsForUserQuery = (
  curriculum_id: number,
  type: string,
  id?: string,
  root_id?: number,
  seed_type?: string,
  seed_id?: number
) => ({
  query: gql`
    query QuestionsForUser($id: String!, $curriculum_id: Int!, $root_id: Int, $type: String!, $seed_type: String, $seed_id: Int) {
      questions_for_user(id: $id, curriculum_id: $curriculum_id, root_id: $root_id, type: $type, seed_type: $seed_type, seed_id: $seed_id) {
        id
        concept_id
        display_name
        passage_id
        read_mode
        type
        content {
          ... on QuestionContent {
            answer {
              hint
              prefill
              value
            }
            choices {
              correct
              hint
              value
            }
            correctPrompt {
              correctValue
              hide
              highlight
              hint
              value
            }
            images {
              id
              key
              caption
            }
            prompt {
              correctValue
              hide
              highlight
              hint
              secondaryHint
              value
            }
          }
          ... on ReadContent {
            image
            imageId
            prompt {
              correctValue
              hide
              highlight
              hint
              value
            }
          }
        }
      }
    }
  `,
  variables: { curriculum_id, id, root_id, type, seed_id, seed_type },
})
